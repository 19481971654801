import React, { useState, useEffect, useContext } from 'react';

import * as Constants from '../../constants/global';
import axios from 'axios';

//import { AppContext } from '../../context/AppState';

import Progress from '../util/Progress';

import {ReactComponent as Minus} from '../../assets/icon/minus.svg';
import {ReactComponent as Plus} from '../../assets/icon/plus.svg';

import descuento from '../../assets/img/descuento.png';
import noimage from '../../assets/img/noimage.png';

import DetalleComboModal from '../modal/DetalleComboModal';

const Producto = (props) => {

    const [carrito, setCarrito] = useState();
    const [loader, setLoader] = React.useState(false);
    const [productos, setProductos] = useState();
    const [combos, setCombos] = useState([]);

    const [detalleCombo, setDetalleCombo] = useState([]);

    // let imagenes = ['COMBOS.jpg', 'INFANTILES.jpg', 'PASABOCAS.jpg', 'PONQUES.jpg'],
	let cont = 0;

    // const [cantidad, setCantidad] = useState();
    let sucursal = localStorage.getItem('sucursal');
    let codCliente = localStorage.getItem('codCliente');
    let nombreCliente = localStorage.getItem('nombrecliente');
    let cupo = localStorage.getItem('cupo');
    let tipocredito = localStorage.getItem('tipocredito');

    const loadBanners = async () => {
        let url = Constants.URL_BASE + Constants.WSBANNERS

        axios.get(url)
        .then(res => {
            efectosBanners(res.data);
        });
    }

    const efectosBanners = (imagenes) => {

        if(props.tipo == 1){

            let contenedor = document.querySelector(".banner-imagenes");
    
            let atras = contenedor.querySelector('.atras'),
                adelante = contenedor.querySelector('.adelante'),
                img = contenedor.querySelector('img');

            carrusel(imagenes, contenedor, atras, adelante, img);

            setInterval(() => {
                if (cont < imagenes.length - 1) {
                    img.src = Constants.URL_BANNERS + imagenes[ cont +1 ]["ruta"];
                    cont ++;
                } else {
                    img.src = Constants.URL_BANNERS + imagenes[0]["ruta"];
                    cont = 0;
                }
            }, 4000);
        }

    }

    // TRAE TODOS LOS PRODUCTOS
    const loadProductos = async (tipo) => {
        let url = Constants.URL_BASE +
                    Constants.WSPRODUCTO+
                    "?items=" + "20";
                    
        if(sucursal){
            url += "&sucursal=" + sucursal;
        }

        if(codCliente){
            url += "&codCliente=" + codCliente;
        }

        if(props.search){
            url += "&search=" + props.search;
        }

        if(tipo == 1){
            if(productos){
                if(productos.now < productos.next){
                    url += "&page=" + productos.next;
                }
            }
        }
        
        axios.get(url)
        .then(res => {
            setLoader(false);
            let array = res.data;
            
            if(tipo == 1){
                if(productos){
                    if(productos.result){
                        let combine = productos.result.concat(array.result);
                        array.result = combine;
                    }
                }
            }

            setProductos(array);
        });

        props.handleCantidad();

        cargarCarrito();
    }

    const cargarCarrito = () => {
        setCarrito(JSON.parse(localStorage.getItem(codCliente)) || {});
    }

    const cargarMas = () => {
        loadProductos(1);
    }

    // AUMENTA LA CANTTIDAD
    const handleMas = (item, cantidad = 0) => {    

        if(carrito[item.codigo]){
            carrito[item.codigo]["cantidad"] += 1;
        }else{
            carrito[item.codigo] = {
                nombre: item.nombre,
                precio: parseFloat(item.precio),
                preciopvp : item.preciopvp,
                pesobruto : item.pesobruto,
                cantant : item.cantant,
                iva: parseInt(item.iva),
                cantidad: (cantidad == 0 ? 1 : cantidad)
            }
        }
        localStorage.setItem(codCliente, JSON.stringify(carrito));
        setCarrito(carrito);
        loadProductos(0);
    }

    const handleMenos = item => {
        if(carrito[item.codigo]){
            if(carrito[item.codigo]["cantidad"] == 1){
                delete carrito[item.codigo];
            }else{
                carrito[item.codigo]["cantidad"] -= 1;
            }
        }
        setCarrito(carrito);
        localStorage.setItem(codCliente, JSON.stringify(carrito));
        loadProductos(0);
    }

    const handleChangeCantidad = (item, valor) => {
        if(valor <= 0){
            delete carrito[item.codigo];
        }else{
            if(carrito[item.codigo]){
                carrito[item.codigo]["cantidad"] = parseInt(valor);
            }else{
                carrito[item.codigo] = {
                    nombre: item.nombre,
                    precio: item.precio,
                    preciopvp : item.preciopvp,
                    pesobruto : item.pesobruto,
                    cantant : item.cantant,
                    iva: parseInt(item.iva),
                    cantidad: parseInt(valor)
                }
            }
        }
        setCarrito(carrito);
        localStorage.setItem(codCliente, JSON.stringify(carrito));
        loadProductos();
    }

    const cargarCombos = () => {
        let url = Constants. URL_BASE + Constants.WSPRODUCTOSCOMBO + "?codCliente=" + codCliente + "&sucursal=" + sucursal;

        axios.get(url)
        .then(res => {
            setCombos(res.data);
        });
    }

    const handleClickCombo = (combo) => {
        
        if(combo.categoria == 'COMBOS') {

            let infoDetalle

            combos.filter(o => o.codigopadre === combo.codigo).map((item, i) => {

                let url = Constants.URL_BASE + Constants.WSPRODUCTO +
                "?items=" + "20";

                if(sucursal){
                    url += "&sucursal=" + sucursal;
                }
        
                if(codCliente){
                    url += "&codCliente=" + codCliente;
                }

                url += "&search=" + item.codigohijo
                
                axios.get(url)
                .then(res => {

                    let array = res.data;

                    if (array.result.length > 0){
                        array.result[0].cantidad = item.cantidad
                    }

                    if(infoDetalle){
                        if(infoDetalle.result){
                            let combine = infoDetalle.result.concat(array.result);
                            array.result = combine;
                        }
                    }
                    
                    infoDetalle = array;

                    setDetalleCombo(infoDetalle);

                })
            })

            document.querySelector(".modal-combo").classList.toggle("show-modal");
        }
    }

    // SE EJECUTA CUANDO CAMBIA CATEGORIA O BUSQUEDA
    useEffect(() => {

        loadBanners();
        setLoader(true);
        setCarrito(JSON.parse(localStorage.getItem(codCliente)) || {});
        
        loadProductos(0);

        cargarCombos();
        
    }, [props.search], carrito)

    const carrusel = (imagenes, contenedor, atras, adelante, img) => {
        contenedor.addEventListener('click', e => {
            
            let tgt = e.target;

            if (tgt == atras) {
                if (cont > 0) {
                    img.src = Constants.URL_BANNERS + imagenes[ cont -1 ]["ruta"];
                    cont --;
                } else {
                    img.src = Constants.URL_BANNERS + imagenes[imagenes.length - 1]["ruta"];
                    cont = imagenes.length - 1;
                }
            } else if(tgt == adelante){
                if (cont < imagenes.length - 1) {
                    img.src = Constants.URL_BANNERS + imagenes[ cont +1 ]["ruta"];
                    cont ++;
                } else {
                    img.src = Constants.URL_BANNERS + imagenes[0]["ruta"];
                    cont = 0;
                }
            }
        })
    }

    // VISTA
    return (
        <div className="main">
            <div className="banner-ramo">
                { props.tipo == 1 ? (
                    <div className="banner-imagenes">
                        <div className="atras botones">
                            &#60;
                        </div>

                        <div className="adelante botones">
                            &#62;
                        </div>

                        <img 
                            src={Constants.URL_BANNERS + 'B1.jpg'} 
                            id="imagen">
                        </img>
                    </div>
                ):null }
            </div>
            <div className='pedido-list padding-banner'>
                <div className='pedido-list'>
                    { loader ? <Progress color={"#007daf"} /> : (
                        <React.Fragment>  
                            
                                {productos ? (
                                    <React.Fragment>
                                        { props.tipo == 2 ? (
                                            
                                            <table className="table-productos">
                                                <thead>
                                                    <tr>
                                                        <th colSpan={"2"} style={{color:"black"}}>{nombreCliente}</th>
                                                        <th colSpan={"2"} style={{color:"black"}}>CUPO: {Constants.copFormat(cupo)}</th>
                                                        <th colSpan={"1"} style={{color:"black"}}>TIPO VENTA: {tipocredito}</th>
                                                        <th colSpan={"2"}>Compra 2019</th>
                                                        <th colSpan={"2"}>Sugerido 2020</th>
                                                        <th colSpan={"3"}>&nbsp;</th>
                                                    </tr>
                                                    <tr>
                                                        <th className={"color-azul"}>Imagen</th>
                                                        <th className={"color-azul"}>Precio 2020</th>
                                                        <th className={"color-azul"}>Precio PVP</th>
                                                        <th className={"color-azul"}>SKU</th>
                                                        <th className={"color-azul"}>Producto</th>
                                                        <th className={"color-azul"}>Und</th>
                                                        <th className={"color-azul"}>Valor</th>
                                                        <th className={"color-naranja"}>Und</th>
                                                        <th className={"color-naranja"}>Valor</th>
                                                        {/* <th className={"color-verde"}>Unidades</th>
                                                        <th className={"color-verde"}>Valor Sin Iva</th>
                                                        <th className={"color-verde"}>Iva</th> */}
                                                        {/* <th className={"color-verde"}>Descuento</th> */}
                                                        <th className={"color-verde"}>Rentabilidad</th>
                                                        <th className={"color-verde"}>Valor Total</th>
                                                        <th className={"color-verde"}>Acción</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {productos.result.map((item, i) => (
                                                        <tr>
                                                            <td>
                                                                <img 
                                                                    width="150"
                                                                    className={item.categoria == 'COMBOS' ? 'img-product cursor' : 'img-product'}
                                                                    onClick={() => handleClickCombo(item)}
                                                                    style={item.categoria == 'COMBOS' ? {'cursor':"pointer"} : {}}
                                                                    src={ Constants.URL_IMG_PRODUCTOS + item.codigo.toString().trim() + '.jpg'}
                                                                    onError={(e)=>{e.target.onerror = null; e.target.src=noimage}}>
                                                                </img>
                                                                {
                                                                    JSON.parse(localStorage.getItem('gruposDescuento')).filter(o => o.codProducto === item.codigo).length > 0 ? 
                                                                    (
                                                                        <img src={descuento} width={50} className="img-descuento"></img>
                                                                    ) : null
                                                                }
                                                            </td>
                                                            <td>{ Constants.copFormat(item.precio * (1 + ((item.iva ? item.iva : 0) / 100))) }</td>
                                                            <td>{ Constants.copFormat(item.preciopvp) }</td>
                                                            <td>{item.codigo}</td>
                                                            <td>{item.nombre}</td>
                                                            <td>{item.cantant}</td>
                                                            <td>{Constants.copFormat( item.valorant )}</td>
                                                            <td>{item.cantsug}</td>
                                                            <td>{ Constants.copFormat( item.cantsug * (item.precio * (1 + ((item.iva ? item.iva : 0) / 100))) ) }</td>
                                                            { carrito[item.codigo] ? (
                                                                <React.Fragment>
                                                                    {/* <td>{carrito[item.codigo].cantidad}</td>
                                                                    <td>{ Constants.copFormat( carrito[item.codigo].cantidad * item.precio - (carrito[item.codigo].descuentoAplicado ? carrito[item.codigo].descuentoAplicado : 0) ) }</td>
                                                                    <td>{ Constants.copFormat( (carrito[item.codigo].cantidad * item.precio - (carrito[item.codigo].descuentoAplicado ? carrito[item.codigo].descuentoAplicado : 0) ) * ( ((item.iva ? item.iva : 0) / 100)) ) }</td> */}
                                                                    {/* <td>{ Constants.copFormat( (carrito[item.codigo].descuentoAplicado ? carrito[item.codigo].descuentoAplicado : 0) ) }</td> */}
                                                                    <td>{ Constants.copFormat( ( (carrito[item.codigo].cantidad * item.preciopvp) - (carrito[item.codigo].cantidad * (item.precio * (1 + ((item.iva ? item.iva : 0) / 100)))) ? (carrito[item.codigo].cantidad * item.preciopvp) - (carrito[item.codigo].cantidad * (item.precio * (1 + ((item.iva ? item.iva : 0) / 100)))) : 0) ) }</td>

                                                                    <td>{ Constants.copFormat( (carrito[item.codigo].cantidad * item.precio - (carrito[item.codigo].descuentoAplicado ? carrito[item.codigo].descuentoAplicado : 0) ) * (1 + ((item.iva ? item.iva : 0) / 100)) ) }</td>
                                                                    <td>
                                                                        <div className="action">
                                                                            <div className="controls">
                                                                                <button role="button" onClick={() => handleMenos(item)}>
                                                                                    {/* <img src={minus} alt="icon minus" width="15px" height="15px"></img> */}
                                                                                    <Minus className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                                                                                </button>
                                                                                <input type="number" 
                                                                                    onChange={e => handleChangeCantidad(item, e.target.value)}
                                                                                    value={carrito[item.codigo].cantidad}/>
                                                                                <button role="button" onClick={() => handleMas(item)}>
                                                                                    {/* <img src={plus} alt="icon plus" width="15px" height="15px"></img> */}
                                                                                    <Plus className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </React.Fragment>
                                                            ):(
                                                                <React.Fragment>
                                                                    {/* <td>0</td>
                                                                    <td>{ Constants.copFormat( 0 ) }</td>
                                                                    <td>{ Constants.copFormat( 0 ) }</td> */}
                                                                    {/* <td>{ Constants.copFormat( 0 ) }</td> */}
                                                                    <td>{ Constants.copFormat( 0 ) }</td>
                                                                    <td>{ Constants.copFormat( 0 ) }</td>
                                                                    <td>
                                                                        <div className="action">
                                                                            <button className="agregar" onClick={() => handleMas(item, item.cantsug)}>Agregar</button>
                                                                        </div>
                                                                    </td>
                                                                </React.Fragment>
                                                            ) }
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                            // <div className="product-list">
                                            //     {productos.result.map((item, i) => (
                                            //         <div className="item" key={i}>
                                            //             <div>
                                            //                 <p className="nombre"><b>{item.nombre}</b></p>
                                            //                 <p className="codigo"><b>Codigo:</b> {item.codigo}
                                            //                 {/* - <b>EAN:</b> {item.ean} */}
                                            //                 </p>
                                            //                 <p className="precio"> { Constants.copFormat(item.precio * (1 + ((item.iva ? item.iva : 0) / 100))) }
                                            //                     {/* {new Intl.NumberFormat("es-CO", {
                                            //                         style: "currency",
                                            //                         currency: "COP"
                                            //                     }).format(item.precio * (1 + ((item.iva ? item.iva : 0) / 100)))} */}
                                            //                 </p>
                                            //             </div>
                                            //             <div className="action">
                                            //                 { carrito[item.codigo] ? (
                                            //                     <div className="controls">
                                            //                         <button role="button" onClick={() => handleMenos(item)}>
                                            //                             {/* <img src={minus} alt="icon minus" width="15px" height="15px"></img> */}
                                            //                             <Minus className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                                            //                         </button>
                                            //                         <input type="number" 
                                            //                             onChange={e => handleChangeCantidad(item, e.target.value)}
                                            //                             value={carrito[item.codigo].cantidad}/>
                                            //                         <button role="button" onClick={() => handleMas(item)}>
                                            //                             {/* <img src={plus} alt="icon plus" width="15px" height="15px"></img> */}
                                            //                             <Plus className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                                            //                         </button>
                                            //                     </div>
                                            //                 ) : (
                                            //                     <button className="agregar" onClick={() => handleMas(item)}>Agregar</button>
                                            //                 )}
                                            //             </div>
                                            //         </div>
                                            //     ))}
                                            // </div>
                                        ) : (
                                            <div className="product-card">
                                                {productos.result.map((item, i) => (
                                                    <div className="card" key={i}>
                                                        <img 
                                                            width="150"
                                                            className="img-product"
                                                            src={ Constants.URL_IMG_PRODUCTOS + item.codigo.toString().trim() + '.jpg'}
                                                            onError={(e)=>{e.target.onerror = null; e.target.src=noimage}}>
                                                        </img>
                                                        <p className="nombre"><b>{item.nombre}</b></p>
                                                        <p className="codigo"><b>Codigo:</b> {item.codigo}</p>
                                                        {/* <p className="codigo"><b>EAN:</b> {item.ean}</p> */}
                                                        {/* <p className="precio"> {Constants.copFormat(item.precio * (1 + ((item.iva ? item.iva : 0) / 100)))}
                                                        </p> */}
                                                        {/* <div className="action">
                                                            { carrito[item.codigo] ? (
                                                                <div className="controls">
                                                                    <button role="button" onClick={() => handleMenos(item)}>
                                                                        <Minus className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                                                                    </button>
                                                                    <input type="number" 
                                                                        onChange={e => handleChangeCantidad(item, e.target.value)}
                                                                        value={carrito[item.codigo].cantidad}/>
                                                                    <button role="button" onClick={() => handleMas(item)}>
                                                                        <Plus className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                                                                    </button>
                                                                </div>
                                                            ) : (
                                                                <button className="agregar" onClick={() => handleMas(item)}>Agregar</button>
                                                            )}
                                                        </div> */}
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                        <div className="load-mas">
                                            {productos.now < productos.next ? (
                                                <button onClick={() => cargarMas()}>Cargar mas</button>
                                            ) : null }
                                        </div>
                                        
                                    </React.Fragment>
                                ) : null}
                        </React.Fragment>
                    )}
                </div>

                <DetalleComboModal detalleCombo={detalleCombo.result} noimage={noimage} />

            </div>
        </div>

    )
}

export default Producto;