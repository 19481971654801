import React, { useState, useEffect } from 'react';

import Navbar from '../navbar/Navbar';
import Tabbar from '../navbar/Tabbar';
import Lista from './Lista';

import * as Constants from '../../constants/global';

const Sugerido = () => {

    let empresa = JSON.parse(localStorage.getItem('empresa'));

    let sucursal = localStorage.getItem('sucursal');
    let codCliente = localStorage.getItem('codCliente');

    const handleCantidad = () => {
        getCantidad();
    }

    const [totalNavbar, setTotalNavbar] = useState();
    const [cantidad, setCantidad] = useState();

    const getCantidad = () => {

        Constants.calcularDescuento()
        let carrito = JSON.parse(localStorage.getItem(codCliente)) || {};

        let iva = 0;
        let total = 0;
        let descuentoTotal = 0;
        let cantidad = 0;

        Object.keys(carrito).map((item, i) => {

            cantidad += carrito[item]["cantidad"];

            let subtotal = (carrito[item]["cantidad"] * carrito[item]["precio"]);
            let descuento = (carrito[item]["descuentoAplicado"] ? carrito[item]["descuentoAplicado"] : 0);
            iva += ( (subtotal - descuento) * (carrito[item]["iva"] ? carrito[item]["iva"] / 100 : 0) );

            descuentoTotal += descuento
        })

        setCantidad(cantidad)

        Object.keys(carrito).map((item, i) => {
            total += carrito[item]["cantidad"] * carrito[item]["precio"];
        })

        setTotalNavbar(total - descuentoTotal + iva);
    }

    useEffect(() => {
        getCantidad();
    }) 

    return (
        <React.Fragment>
            <div className="navbar principal">
                <Navbar item={2} cantidad={cantidad} totalNavbar={totalNavbar} />
            </div>
            <Tabbar item={2}/>

            <Lista handleCantidad={handleCantidad} />

        </React.Fragment>
    )
}


export default Sugerido;