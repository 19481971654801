import React, { useEffect } from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

import { AppProvider } from "./context/AppState";

// COMPONENTS
import Login from "./components/auth/Login";
import Home from "./components/home/Home";
import Pedido from "./components/pedido/Pedido";
import Sugerido from "./components/pedido/Sugerido";
import Catalogo from "./components/pedido/Catalogo";
import Carrito from "./components/pedido/Carrito";
import Excel from "./components/pedido/Excel";
import Historial from "./components/pedido/Historial";
import UltimosPedidos from "./components/pedido/UltimosPedidos";
import Cartera from "./components/pedido/Cartera";
import Contactenos from "./components/pedido/Contactenos";
import Ayuda from "./components/pedido/Ayuda";
import ListadoClientes from "./components/pedido/ListadoClientes";
import Agendamiento from "./components/pedido/Agendamiento";
import Perfil from "./components/pedido/Perfil";
import DistribucionInventario from "./components/pedido/DistribucionInventario";
import Descuentos from "./components/pedido/Descuentos";

import Auth from "./helpers/Auth";

import * as Constants from "./constants/global";

import "toasted-notes/src/styles.css";

// SASS

import "./styles/app.scss";
import { Historico } from "./components/historico/Historico";
import { MyAccount } from "./components/perfil/MyAccount";

// export const cambiarTema = (empresa) => {
//   if (empresa){
//     if (empresa.num_empresa == Constants.num_empresa_ramo) {
//       import ('./styles/appRamo.scss').then((Appcss) => {
//       });
//     } else {
//       import ('./styles/app.scss').then((Appcss) => {
//       });
//     }
//   } else {
//     import ('./styles/app.scss').then((Appcss) => {
//     });
//   }
// }

// COMPONENTES
const App = () => {
  useEffect(() => {
    // cambiarTema(null);
  });

  return (
    <AppProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={Login} />
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute path="/pedido" component={Pedido} />
          <PrivateRoute path="/sugerido" component={Sugerido} />
          <PrivateRoute path="/catalogo" component={Catalogo} />
          <PrivateRoute path="/excel" component={Excel} />
          <PrivateRoute path="/carrito" component={Carrito} />
          <PrivateRoute path="/historial" component={Historial} />
          <PrivateRoute path="/ultimosPedidos" component={UltimosPedidos} />
          <PrivateRoute path="/cartera" component={Cartera} />
          <PrivateRoute path="/contactenos" component={Contactenos} />
          <PrivateRoute path="/ayuda" component={Ayuda} />
          <PrivateRoute path="/listadoClientes" component={ListadoClientes} />
          <PrivateRoute path="/agendamiento" component={Agendamiento} />
          <PrivateRoute path="/historico" component={Historico} />
          <PrivateRoute path="/perfil" component={Perfil} />
          <PrivateRoute path="/myaccount" component={MyAccount} />
          <PrivateRoute
            path="/distribucionInventario"
            component={DistribucionInventario}
          />
          <PrivateRoute path="/descuentos" component={Descuentos} />
        </Switch>
      </BrowserRouter>
    </AppProvider>
  );
};

// RUTAS PRIVADAS
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      Auth.isAuthenticated === true ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

export default App;
