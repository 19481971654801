import React, { useState, useEffect, useContext } from 'react';

import * as Constants from '../../constants/global';
import axios from 'axios';

//import { AppContext } from '../../context/AppState';

import Progress from '../util/Progress';

import {ReactComponent as Minus} from '../../assets/icon/minus.svg';
import {ReactComponent as Plus} from '../../assets/icon/plus.svg';

import noimage from '../../assets/img/noimage.png';
import DetalleComboModal from '../modal/DetalleComboModal';

const Producto = (props) => {

    const [loader, setLoader] = React.useState(false);
    const [productos, setProductos] = useState();
    const [combos, setCombos] = useState([]);

    const [detalleCombo, setDetalleCombo] = useState([]);

    // let imagenes = ['COMBOS.jpg', 'INFANTILES.jpg', 'PASABOCAS.jpg', 'PONQUES.jpg'],
	let cont = 0;

    // const [cantidad, setCantidad] = useState();
    let sucursal = localStorage.getItem('sucursal');
    let codCliente = localStorage.getItem('codCliente');
    let nombreCliente = localStorage.getItem('nombrecliente');
    let cupo = localStorage.getItem('cupo');
    let tipocredito = localStorage.getItem('tipocredito');

    const loadBanners = async () => {
        let url = Constants.URL_BASE + Constants.WSBANNERS

        axios.get(url)
        .then(res => {
            efectosBanners(res.data);
        });
    }

    const efectosBanners = (imagenes) => {


        let contenedor = document.querySelector(".banner-imagenes");

        let atras = contenedor.querySelector('.atras'),
            adelante = contenedor.querySelector('.adelante'),
            img = contenedor.querySelector('img');

        carrusel(imagenes, contenedor, atras, adelante, img);

        setInterval(() => {
            if (cont < imagenes.length - 1) {
                img.src = Constants.URL_BANNERS + imagenes[ cont +1 ]["ruta"];
                cont ++;
            } else {
                img.src = Constants.URL_BANNERS + imagenes[0]["ruta"];
                cont = 0;
            }
        }, 4000);

    }

    // TRAE TODOS LOS PRODUCTOS
    const loadProductos = async (tipo) => {
        let url = Constants.URL_BASE +
                    Constants.WSPRODUCTOCATALOGO+
                    "?items=" + "20";

        if(props.search){
            url += "&search=" + props.search;
        }

        if(tipo == 1){
            if(productos){
                if(productos.now < productos.next){
                    url += "&page=" + productos.next;
                }
            }
        }

        // console.log("url catalogo", url);
        
        axios.get(url)
        .then(res => {
            setLoader(false);
            let array = res.data;
            
            if(tipo == 1){
                if(productos){
                    if(productos.result){
                        let combine = productos.result.concat(array.result);
                        array.result = combine;
                    }
                }
            }

            setProductos(array);
        });

        props.handleCantidad();

    }

    const cargarMas = () => {
        loadProductos(1);
    }

    const cargarCombos = () => {
        let url = Constants. URL_BASE + Constants.WSPRODUCTOSCOMBO

        // console.log("url combo", url);

        axios.get(url)
        .then(res => {
            setCombos(res.data);
        });
    }

    const handleClickCombo = (combo) => {
        
        if(combo.categoria == 'COMBOS') {

            let infoDetalle

            combos.filter(o => o.codigopadre === combo.codigo).map((item, i) => {

                let url = Constants.URL_BASE + Constants.WSPRODUCTOCATALOGO +
                    "?items=" + "20" + 
                    "&search=" + item.codigohijo;

                // console.log("url busqueda combo", url);
                
                axios.get(url)
                .then(res => {

                    let array = res.data;

                    if (array.result.length > 0){
                        array.result[0].cantidad = item.cantidad
                    }

                    if(infoDetalle){
                        if(infoDetalle.result){
                            let combine = infoDetalle.result.concat(array.result);
                            array.result = combine;
                        }
                    }
                    
                    infoDetalle = array;

                    setDetalleCombo(infoDetalle);

                })
            })

            document.querySelector(".modal-combo").classList.toggle("show-modal");
        }
    }

    // SE EJECUTA CUANDO CAMBIA CATEGORIA O BUSQUEDA
    useEffect(() => {

        loadBanners();
        setLoader(true);
        
        loadProductos(0);

        cargarCombos();
        
    }, [props.search])

    const carrusel = (imagenes, contenedor, atras, adelante, img) => {
        contenedor.addEventListener('click', e => {
            
            let tgt = e.target;

            if (tgt == atras) {
                if (cont > 0) {
                    img.src = Constants.URL_BANNERS + imagenes[ cont -1 ]["ruta"];
                    cont --;
                } else {
                    img.src = Constants.URL_BANNERS + imagenes[imagenes.length - 1]["ruta"];
                    cont = imagenes.length - 1;
                }
            } else if(tgt == adelante){
                if (cont < imagenes.length - 1) {
                    img.src = Constants.URL_BANNERS + imagenes[ cont +1 ]["ruta"];
                    cont ++;
                } else {
                    img.src = Constants.URL_BANNERS + imagenes[0]["ruta"];
                    cont = 0;
                }
            }
        })
    }

    // VISTA
    return (
        <div className="main">
            <div className="banner-ramo">
                
                    <div className="banner-imagenes">
                        <div className="atras botones">
                            &#60;
                        </div>

                        <div className="adelante botones">
                            &#62;
                        </div>

                        <img 
                            src={Constants.URL_BANNERS + 'B1.jpg'} 
                            id="imagen">
                        </img>
                    </div>
                
            </div>
            <div className='lista-catalogo padding-banner'>
                <div className='pedido-list'>
                    { loader ? <Progress color={"#007daf"} /> : (
                        <React.Fragment>  
                            {productos ? (
                                <React.Fragment>
                                        <div className="product-card">
                                            {productos.result.map((item, i) => (
                                                <div className="card" key={i}>
                                                    <img 
                                                        width="150"
                                                        className="img-product"
                                                        onClick={() => handleClickCombo(item)}
                                                        style={item.categoria == 'COMBOS' ? {'cursor':"pointer"} : {}}
                                                        src={ Constants.URL_IMG_PRODUCTOS + item.codigo.toString().trim() + '.jpg'}
                                                        onError={(e)=>{e.target.onerror = null; e.target.src=noimage}}>
                                                    </img>
                                                    <p className="nombre"><b>{item.nombre}</b></p>
                                                    <p className="codigo"><b>Codigo:</b> {item.codigo}</p>
                                                </div>
                                            ))}
                                        </div>
                                    <div className="load-mas">
                                        {productos.now < productos.next ? (
                                            <button onClick={() => cargarMas()}>Cargar mas</button>
                                        ) : null }
                                    </div>
                                    
                                </React.Fragment>
                            ) : null}
                        </React.Fragment>
                    )}
                </div>

                <DetalleComboModal detalleCombo={detalleCombo.result} noimage={noimage} />

            </div>
        </div>

    )
}

export default Producto;