import React, { useState, useEffect, useContext } from 'react';

import * as Constants from '../../constants/global';
import axios from 'axios';

import Progress from '../util/Progress';
import { withRouter } from "react-router-dom";

import imagecliente from '../../assets/img/cliente.png';

const Agenda = (props) => {

    const [loader, setLoader] = React.useState(false);
    const [agendamientos, setAgendamientos] = useState();

    // const [cantidad, setCantidad] = useState();
    let usuario = localStorage.getItem("usuario");

    // TRAE TODOS LOS PRODUCTOS
    const loadAgendamiento = async () => {

        let url = Constants.URL_BASE +
                    Constants.WSAGENDAMIENTO +
                    "?date=" + (props.fecha ? props.fecha : Constants.getDate(0,"-")) +
                    "&usuario=" + usuario;

        if(props.search){
            url += "&search=" + props.search;
        }
        if(props.agencia){
            url += "&agencia=" + props.agencia;
        }
        if(props.zona){
            url += "&zona=" + props.zona;
        }
        // if(props.tiposucursal){
        //     url += "&essucursal=" + props.tiposucursal;
        // }
        
        axios.get(url)
        .then(res => {
            setLoader(false);
            
            setAgendamientos(res.data);
        });
    }

    const handlePedido = (item) => {

        // if(item.tienepedido == "1"){
        //     alert("Este cliente ya tiene pedido");
        // }

        if(item.usuario == usuario){

            localStorage.setItem("codCliente", item.codCliente)
            localStorage.setItem("sucursal", item.sucursal)
            localStorage.setItem("essucursal", item.essucursal)
            localStorage.setItem("nombrecliente", item.nombre)
            localStorage.setItem("cupo", item.cupo)
            localStorage.setItem("tipocredito", item.tipocredito)

            Constants.cargarListasDescuento(item.sucursal, item.codCliente, item.tipocredito);
            Constants.cargarGruposDescuento(item.sucursal, item.codCliente, item.tipocredito);
            
            props.history.push('/Pedido');
        } else {
            alert("EL CLIENTE YA SE ENCUENTRA AGENDADO POR OTRO USUARIO: " + item.nombreusuario + "\nEN LA FECHA: " + item.fecha + "\nHORA INICIAL: " + item.horaInicial + "\nHORA FINAL: " + item.horaFinal);
        }
    }

    const handleEliminar = (item) => {
        
        let url = Constants.URL_BASE + Constants.WSAGENDAMIENTO;

        let info = [];

        info.push({
            "Usuario": usuario,
            "CodCliente": item.codCliente,
            "Sucursal": item.sucursal,
            "FechaVisita": item.fecha,
            "HoraInicial": item.horaInicial,
            "HoraFinal": item.horaFinal,
            "EsSucursal": item.essucursal,
            "Regional": item.regional
        })

        let requestData = {"ListaDetalleAgenda": info}

        axios.delete(url, {
            headers: {
              'Content-Type': 'application/json'
            },
            data: requestData
        }).then(res => {
            if(res.data.Estado == "OK"){
                alert(res.data.Mensaje);
            } else {
                alert(res.data.Mensaje);
            }

            loadAgendamiento();
        })
        
    }

    useEffect(() => {

        setLoader(true);

        if (props.zona){
            loadAgendamiento();
        }
        
    }, [props.zona, props.search, props.agencia, props.tiposucursal, props.fecha])

    // VISTA
    return (
        <div className="main">
            <div className="banner-ramo">
            </div>
            <div className='lista-agenda'>
                <div className='pedido-list'>
                    { loader ? <Progress color={"#007daf"} /> : (
                        <React.Fragment>  
                            {agendamientos ? (
                                <React.Fragment>
                                    <div className="product-card">
                                        {agendamientos.map((item, i) => (
                                            <div className="card" style={item.tienepedido == "1" ? {"background":"rgba(225, 18, 35, 0.09)"} : {}} key={i}>
                                                <img 
                                                    width="130"
                                                    className="img-product"
                                                    src={imagecliente}>
                                                </img>
                                                <div className="card-content">
                                                    <p className="txt-principal"><b>Nombre:</b> {item.nombre}</p>
                                                    <p className="txt-principal"><b>Codigo:</b> {item.codCliente}</p>
                                                    {
                                                        item.essucursal == "N" ? (
                                                            <p className="txt-secundario"><b>Sucursal:</b> {item.sucursal}</p>
                                                        ):null 
                                                    }

                                                    <p className="txt-secundario"><b>Fecha:</b> {item.fecha}</p>
                                                    <p className="txt-secundario"><b>Hora Inicial:</b> {item.horaInicial}</p>
                                                    <p className="txt-secundario"><b>Hora Final:</b> {item.horaFinal}</p>
                                                </div>
                                                
                                                <div className="action">
                                                    <button className="agregar" onClick={() => handlePedido(item)}>Tomar Pedido</button>
                                                    <button className="btn-eliminar" onClick={() => handleEliminar(item)}>Eliminar</button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </React.Fragment>
                            ) : null}
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    )
}

export default withRouter(Agenda);