import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import close from '../../assets/icon/close_grey.svg';

import * as Constants from '../../constants/global';

import SolicitudActivacionModal from '../modal/SolicitudActivacionModal';

import axios from 'axios';


const RegistroModal = (props) => {

    const [correo, setCorreo] = React.useState(''); 
    // const [correoDian, setCorreoDian] = React.useState(''); 
    // const [celular, setCelular] = React.useState("");

    const [errorCorreo, setErrorCorreo] = React.useState("");
    // const [errorCorreoDian, setErrorCorreoDian] = React.useState("");
    // const [errorCelular, setErrorCelular] = React.useState("");

    const [correos, setCorreos] = useState({Correos: []});


    const handleClose = () => {
        document.querySelector(".modal-registro").classList.remove("show-modal");
    }

    const handleRegistro = (data) => {
        let url = Constants.URL_BASE + Constants.WSREGISTROUSUARIO;
        axios.post(url, data)
        .then(res => {

            if(res.data.Registro === "1") {

                document.querySelector(".modal-activacion").classList.toggle("show-modal");

            } else {
                alert(res.data.Mensaje)
            }
        });
    }

    const handleCorreo = () => {

        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

        let correoN = 0
        // let correoDianN = 0
        // let celularN = 0

        let totalErrores = 0

        if(! correo.match(mailformat)) {
            setErrorCorreo("El correo ingresado no es válido")
            correoN = 1
        } else {
            setErrorCorreo("")
            correoN = 0
        }

        // if(! correoDian.match(mailformat)) {
        //     setErrorCorreoDian("El correo ingresado no es válido")
        //     correoDianN = 1
        // } else {
        //     setErrorCorreoDian("")
        //     correoDianN = 0
        // }

        // if(celular == "") {
        //     setErrorCelular("Debe Ingresar una clave")
        //     celularN = 1
        // } else {
        //     setErrorCelular("")
        //     celularN = 0
        // }

        totalErrores = correoN 
        // + celularN

        if(totalErrores == 0) {

            let data = {
                "Nit": props.nit,
                "Correo": correo,
                // "CorreoDian": correoDian,
                // "Telefono": celular,
            };

            setCorreos({ Correos: [correo].filter((v, i, a) => a.indexOf(v) === i) })

            handleRegistro(data)
        }

    }

    return (
        <div className="modal modal-registro">
            <div className="modal-content">       
                <div className="title">
                    <span className="close-button" onClick={() => handleClose()}>
                        <img src={close} width="15" height="15" alt="icon close"/>
                    </span>  
                    <p>Registro de usuario</p>
                </div>
                <div className="content sm default">
                    <input 
                        type="number"
                        placeholder="Nit"
                        disabled
                        value={props.nit}
                        style={{cursor:"not-allowed"}}>
                    </input>
                    <input 
                        type="email"
                        placeholder="E-mail"
                        onChange={e => setCorreo(e.target.value)}
                        value={correo}>
                    </input>
                    {/* <small>{props.correoCodigo}</small> */}
                    { errorCorreo.length > 0 ? (
                        <span className="error">
                            <p>{errorCorreo}</p>
                        </span>
                    ) : null }
                    {/* <input 
                        type="number"
                        placeholder="Celular"
                        onChange={e => setCelular(e.target.value)}
                        value={celular}>
                    </input> */}
                    {/* <small>{props.celularAyuda}</small> */}
                    {/* { errorCelular.length > 0 ? (
                        <span className="error">
                            <p>{errorCelular}</p>
                        </span>
                    ) : null } */}
                    
                </div>
                
                <div className="footer">
                    <button type="submit" className="btn-large" onClick={() => handleCorreo()}>Registrarse</button>
                </div>
            </div>

            {/* { props.validacion.Telefonos ?  */}
                <SolicitudActivacionModal nit={props.nit} empresa={props.empresa} validacion={props.validacion} correos={correos} /> 
            {/* :(null)} */}

        </div>
        
        
    )
}

export default withRouter(RegistroModal);