import React, { useState, useEffect } from "react";
import NavbarInicial from "../navbar/NavbarInicial";
import TabbarInicial from "../navbar/TabbarInicial";

import { ReactComponent as Search } from "../../assets/icon/search.svg";
import Encabezado from "./Encabezado";
import Axios from "axios";
import * as Constants from "../../constants/global";

export const Historico = () => {
  const [search, setSearch] = useState("");

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleBuscarCliente();
    }
  };

  const handleBuscarCliente = () => {
    let busqueda = document.getElementById("search").value;
    setSearch(busqueda);
  };

  return (
    <React.Fragment>
      <div className="navbar">
        <div className="navbar-header">
          <NavbarInicial item={4} />
          <div className="input-search">
            <input
              type="text"
              placeholder="Buscar cliente..."
              name="search"
              id="search"
              onKeyPress={handleKeyPress}
              onChange={handleBuscarCliente}
              // onChange={(e) => setSearch(e.target.value)}
              // value={search}>
            ></input>
            <div className="button-Search">
              <button onClick={() => alert("buscar")}>
                <Search
                  className="svg-una-capa"
                  style={{ width: "25px", height: "25px" }}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Encabezado search={search} />

      <TabbarInicial item={4}/>

    </React.Fragment>
  );
};
