import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import * as Constants from "../../constants/global";
import { isMobile } from "./../../helpers/Device";

import logo_login from "../../assets/img/logo_interior.png";
import noimage from "../../assets/img/noimage.png";
import { ReactComponent as Logout_icon } from "../../assets/icon/logout_icon.svg";

const NavbarInicial = (props) => {
  const handleRedirect = (item) => {
    props.history.replace(item, "urlhistory");
  };

  const handleLogOut = (item) => {
    props.history.push("/login");
  };

  const [tipoUsuario, setTipoUsuario] = useState("");

  window.onscroll = function () {
    if (isMobile()) {
      if (document.getElementById("nav-hidden")) {
        var currentScrollPos = window.pageYOffset;
        if (currentScrollPos > 20) {
          document.getElementById("nav-hidden").style.display = "none";
          document.getElementById("nav-hidden").style.opacity = "0";
          document.getElementById("nav-hidden").style.transform =
            "translate(9999px)";
        } else {
          document.getElementById("nav-hidden").style.display = "initial";
          document.getElementById("nav-hidden").style.opacity = "initial";
        }
      }
    }
  };

  useEffect(() => {
    setTipoUsuario(localStorage.getItem("tipoUsuario"));
  });

  return (
    <div className="nav-header navbar-inicial" id="nav-hidden">
      <div className="nav-content">
        <div className="subnav">
          <div
            className="back"
            onClick={() => handleLogOut()}
            title="Cerrar Sesión"
          >
            {/* <img src={back} alt="icon menu" width="15px" height="15px"></img> */}
            <Logout_icon
              className="svg-una-capa"
              style={{ width: "20px", height: "20px" }}
            />
          </div>
          <div className="nav-logo">
            <img
              src={logo_login}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = noimage;
              }}
            ></img>
          </div>
        </div>
        <div className="navmenu">
          <ul>
            <li>
              <a
                className={props.item == 1 ? "tab active" : "tab"}
                onClick={() => handleRedirect("/catalogo")}
              >
                Catálogo
              </a>
            </li>
            {tipoUsuario === "us" ? (
              <li>
                <a
                  className={props.item == 2 ? "tab active" : "tab"}
                  onClick={() => handleRedirect("/listadoClientes")}
                >
                  Clientes
                </a>
              </li>
            ) : null}
            {tipoUsuario === "us" ? (
              <li>
                <a
                  className={props.item == 3 ? "tab active" : "tab"}
                  onClick={() => handleRedirect("/agendamiento")}
                >
                  Agendamiento
                </a>
              </li>
            ) : null}
            <li>
              <a
                className={props.item == 4 ? "tab active" : "tab"}
                onClick={() => handleRedirect("/historico")}
              >
                Historico
              </a>
            </li>
            {tipoUsuario === "us" ? (
              <li>
                <a
                  className={props.item == 5 ? "tab active" : "tab"}
                  onClick={() => handleRedirect("/myaccount")}
                >
                  Perfil
                </a>
              </li>
            ) : null}
            {tipoUsuario === "cl" ? (
              <li>
                <a className="tab" onClick={() => handleRedirect("/pedido")}>
                  Tomar Pedido
                </a>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default withRouter(NavbarInicial);
