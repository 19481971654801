import React, { useState, useEffect, Fragment } from 'react';

import * as Constants from '../../constants/global';
import axios from 'axios';

const Filtros = (props) => {
    
    const [agencias, setAgencias] = useState([{codigo:"-1", nombre:"No Asignado"}]);
    const [zonas, setZonas] = useState([{codigo:"-1", nombre:"No Asignado"}]);

    const [tipoSucursal, setTipoSucursal] = useState("N");
    const [fecha, setFecha] = useState();

    let usuario = localStorage.getItem("usuario");

    useEffect(() => {
        
        loadAgencias();
        
    }, [])

    const loadAgencias = () => {

        let url = Constants.URL_BASE + Constants.WSFILTROAGENCIA;

        let data = {
            "Codigo" : usuario
        }

        axios.post(url, data)
        .then(res => {

            let listData = JSON.parse(res.data)

            listData = listData.length > 0 ? listData : [{codigo:"-1", nombre:"No Asignado"}];
            
            setFecha(Constants.getDate(0, "-"))
            setAgencias(listData);
            loadZonas(listData[0].codigo || null);

        });

    }

    const loadZonas = (agencia) => {
        let url = Constants.URL_BASE + Constants.WSFILTROZONA;

        let data = {
            "Codigo" : usuario,
            "Agencia" : agencia || ""
        }

        axios.post(url, data)
        .then(res => {

            let listData = JSON.parse(res.data)

            listData = listData.length > 0 ? listData : [{codigo:"-1", nombre:"No Asignado"}];

            setZonas(listData);

            props.handleFiltros(tipoSucursal, agencia, listData[0].codigo);

        });
    }

    const handleFecha = (item) => {
        setFecha(fecha);
        props.handleFecha(item);
    }

    const handleSucursal = (item) => {
        setTipoSucursal(item);
        props.handleTipoSucursal(item);
    }

    const handleAgencia = (item) => {
        props.handleAgencia(item);
        loadZonas(item);
    }

    const handleZona = (item) => {
        props.handleZona(item);
    }

    return (
        <Fragment>
            <div className="filtro">
                <div style={{ backgroundColor:'white', width:'100%', display:'flex' }}>
                    <ul id="scroll-categoria">

                        { props.ocultar ? (
                            <input 
                            type="date" 
                            id="fecha"
                            onChange={e => handleFecha(e.target.value)}
                            defaultValue={fecha}
                            min={Constants.getDate(0, "-")} />
                        ) : null }

                        { ! props.ocultar ? (

                            <select className={"select-css"} onChange={(e) => handleSucursal(e.target.value)}>
                                <option value="N" >Clientes</option>
                                <option value="S" >Sucursal</option>
                            </select>

                        ) : null }

                        {tipoSucursal == "N" && ! props.ocultar ? (

                            <Fragment>
                                <select className={"select-css"} onChange={(e) => handleAgencia(e.target.value)} id={"filtroAgencia"} >
                                    {agencias.map(function(item, i){
                                        return (
                                            <option value={item.codigo} key={i}>{item.nombre}</option>
                                        );
                                    })}
                                </select>
                                
                                <select className={"select-css"} onChange={(e) => handleZona(e.target.value)} id={"filtroZona"}>
                                    {zonas.map(function(item, i){
                                        return (
                                            <option value={item.codigo} key={i}>{item.nombre}</option>
                                        );
                                    })}
                                </select>
                            </Fragment>

                        ) : null}
                    </ul>
                </div>
            </div>
        </Fragment> 
    )
}


export default Filtros;