import React, { useState, useEffect } from 'react';

import { withRouter } from "react-router-dom";
import * as Constants from '../../constants/global';
import axios from 'axios';

import Progress from '../util/Progress';
import imagecliente from '../../assets/img/cliente.png';

import AgendamientoModal from '../modal/AgendamientoModal';

import {RadioGroup, Radio} from 'react-radio-group'

const Cliente = (props) => {

    const [loader, setLoader] = React.useState(false);
    const [clientes, setClientes] = useState();

    const [sucursal, setSucursal] = useState("");
    const [codCliente, setCodCliente] = useState("");
    const [esSucursal, setEsSucursal] = useState("");
    const [regional, setRegional] = useState("");

    const [estadoCliente, setEstadoCliente] = useState("0");

    let usuario = localStorage.getItem("usuario");
    let tipoUsuario = localStorage.getItem("tipoUsuario");

    // TRAE TODOS LOS PRODUCTOS
    const loadClientes = async (tipo) => {
        let url = Constants.URL_BASE +
                    Constants.WSGETCLIENTE +
                    "?usuario=" + usuario;

        if(props.search){
            url += "&search=" + props.search;
        }
        if(props.agencia){
            url += "&agencia=" + props.agencia;
        }
        if(props.zona){
            url += "&zona=" + props.zona;
        }
        if(props.tiposucursal){
            url += "&essucursal=" + props.tiposucursal;
        }
        if(tipoUsuario){
            url += "&tipoUsuario=" + tipoUsuario;
        }
        if(estadoCliente){
            url += "&estadoCliente=" + estadoCliente;
        }
        
        if(tipo == 1) {
            if(clientes) {
                if(clientes.now < clientes.next){
                    url += "&page=" + clientes.next;
                }
            }
        }
        
        axios.get(url)
        .then(res => {
            setLoader(false);
            let array = res.data;
            
            if(tipo == 1){
                if(clientes){
                    if(clientes.result){
                        let combine = clientes.result.concat(array.result);
                        array.result = combine;
                    }
                }
            }
            
            setClientes(array);

            setLoader(false);
        });
    }

    const cargarMas = () => {
        setLoader(true)
        loadClientes(1);
    }

    // SE EJECUTA CUANDO CAMBIA CATEGORIA O BUSQUEDA
    useEffect(() => {

        setLoader(true);

        if (props.zona){
            loadClientes(0);
        }
    }, [props.tiposucursal, props.agencia, props.zona, props.search, estadoCliente])

    const handleAgendar = (item) => {
        setSucursal(item.sucursal);
        setCodCliente(item.codigo);
        setEsSucursal(item.essucursal);
        setRegional(item.regional);
        document.querySelector(".modal-agendamiento").classList.toggle("show-modal");
    }

    const handleOpcionRadioChange = (value) => {
        setEstadoCliente(value);
    }

    const handlePedido = (item) => {

        if(item.usuario == usuario){
            localStorage.setItem("codCliente", item.codigo)
            localStorage.setItem("sucursal", item.sucursal)
            localStorage.setItem("essucursal", item.essucursal)
            localStorage.setItem("nombrecliente", item.nombre)
            localStorage.setItem("cupo", item.cupo)
            localStorage.setItem("tipocredito", item.tipocredito)

            Constants.cargarListasDescuento(item.sucursal, item.codigo, item.tipocredito);
            Constants.cargarGruposDescuento(item.sucursal, item.codigo, item.tipocredito);
            
            props.history.push('/Pedido');
        } else {
            alert("EL CLIENTE YA SE ENCUENTRA AGENDADO POR OTRO USUARIO: " + item.nombreusuario + "\nEN LA FECHA: " + item.fecha + "\nHORA INICIAL: " + item.horaInicial + "\nHORA FINAL: " + item.horaFinal);
        }
    }

    const handleEliminar = (item) => {
        
        let url = Constants.URL_BASE + Constants.WSAGENDAMIENTO;

        let info = [];

        info.push({
            "Usuario": usuario,
            "CodCliente": item.codigo,
            "Sucursal": item.sucursal,
            "FechaVisita": item.fecha,
            "HoraInicial": item.horaInicial,
            "HoraFinal": item.horaFinal,
            "EsSucursal": item.essucursal,
            "Regional": item.regional
        })

        let requestData = {"ListaDetalleAgenda": info}

        axios.delete(url, {
            headers: {
              'Content-Type': 'application/json'
            },
            data: requestData
        }).then(res => {
            if(res.data.Estado == "OK"){
                alert(res.data.Mensaje);
            } else {
                alert(res.data.Mensaje);
            }

            loadClientes(0);
        });
    }

    // VISTA
    return (
        <div className="main">
            <div className='cliente-list'>
                <React.Fragment>  
                        {clientes ? (
                            <React.Fragment>
                                <div className="product-list">

                                <div className="item" >
                                    <RadioGroup className={"radio-group"} name="opcion" selectedValue={estadoCliente} onChange={handleOpcionRadioChange.bind(this)}>
                                        <label>
                                            <Radio value={"0"} /> SIN AGENDAR
                                        </label>
                                        <label>
                                            <Radio value={"1"} /> AGENDADOS
                                        </label>
                                    </RadioGroup>
                                </div>

                                {clientes.result.map(function(item, i){
                                    return (
                                        <div className="item" style={item.tienepedido == "1" ? {"background":"rgba(225, 18, 35, 0.09)"} : {}} key={i}>
                                            <div className={"img-cliente"}>
                                                <img width="250"className="img-product"src={imagecliente}/>
                                            </div>
                                            <div>
                                                <p className="nombre"><b>Nombre:</b> {item.nombre}</p>
                                                <p className="codigo"><b>Código:</b> {item.codigo}</p>
                                                { item.essucursal == "N" ?
                                                    <p className="sucursal"><b>Sucursal:</b> {item.sucursal}</p> : null
                                                }
                                                <p className="zona"><b>Zona:</b> {item.zona}</p>
                                                <p className="direccion"><b>Dirección:</b> {item.direccion}</p>

                                                { estadoCliente == "1" ? (
                                                    <React.Fragment>
                                                        <p className="fecha"><b>Fecha:</b> {item.fecha}</p>
                                                        <p className="horaInicial"><b>Hora Inicial:</b> {item.horaInicial}</p>
                                                        <p className="horaFinal"><b>Hora Final:</b> {item.horaFinal}</p>
                                                    </React.Fragment>
                                                ) : null }

                                                {/* <p className="razon-social"><b>Razón Social:</b> {item.razonsocial}</p>
                                                <p className="ciudad"><b>Ciudad:</b> {item.ciudad}</p> */}
                                            </div>
                                            { tipoUsuario == "us" && estadoCliente == "0" ? (
                                                <div className="action">
                                                    <button className="btn-agendar" onClick={() => handleAgendar(item)}>Agendar</button>
                                                </div>
                                            ) : (
                                                <div className="action">
                                                    <button className="agregar" onClick={() => handlePedido(item)}>Tomar Pedido</button>
                                                    <button className="btn-eliminar" onClick={() => handleEliminar(item)}>Eliminar</button>
                                                </div> 
                                            )}
                                        </div>
                                    )}
                                )}
                                </div>
                                { loader ? <Progress color={"#007daf"} /> : null } 

                                <div className="load-mas">
                                    {clientes.now < clientes.next ? (
                                        <button onClick={() => cargarMas()}>Cargar más</button>
                                    ) : null }
                                </div>
                            </React.Fragment>
                        ) : null}
                        <AgendamientoModal sucursal={sucursal} codCliente={codCliente} essucursal={esSucursal} regional={regional} loadClientes={loadClientes} />
                </React.Fragment>
            </div>
        </div>

    )
}

export default withRouter(Cliente);