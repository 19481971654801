import React, { useState, useEffect } from 'react';
// import { NetworkInfo } from "react-native-network-info";

import { withRouter } from "react-router-dom";
import * as Constants from '../../constants/global';
import axios from 'axios';
import Progress from '../util/Progress';

// import logo from '../../assets/img/logo.png';
import logo_login from '../../assets/img/logo_login.png';
// import maqueta from '../../assets/img/maqueta.png';
import subtitulo from '../../assets/img/subtitulo.png';
import Auth from '../../helpers/Auth';

import Fondo from '../../assets/img/bg_login.png';

import LoginModal from '../modal/LoginModal';

import RegistroModal from '../modal/RegistroModal';
import CodigoActivacionModal from '../modal/CodigoActivacionModal';
import RecuperacionClaveModal from '../modal/RecuperacionClaveModal';

const Login = ({history, props}) => {

    const [usuario, setUsuario] = React.useState('');
    const [clave, setClave] = React.useState('');
    const [loader, setLoader] = React.useState(false);
    const [error, setError] = React.useState("");
    const [correoCodigo, setCorreoCodigo] = React.useState("");
    const [celularAyuda, setCelularAyuda] = React.useState("");
    // const [ipv4, setIpv4] = React.useState("");

    const [validacion, setValidacion] = useState({Telefonos: []});
    const [empresa, setEmpresa] = React.useState("");
    const [tipoUsuario, setTipoUsuario] = React.useState("us");

    // const publicIp = require('public-ip');
 
    // const getIpv4 = async () => {
    //     setIpv4(await publicIp.v4());        
    // };

    useEffect(() => {
        localStorage.removeItem("usuario");
        localStorage.removeItem("tipoUsuario");
    })

    // CONSULTA LAS EMPRESAS
    const handleSubmit = event => {

        if (tipoUsuario == "us"){
            setLoader(true);

            let url = Constants.URL_BASE + Constants.WSLOGINCLIENTE;

            let data = {
                "Usuario": usuario.trim(),
                "Clave": clave.trim(),
                "TipoUsuario": tipoUsuario
            };

            axios.post(url, data)
            .then(resLogin => {

                if(resLogin.data.Login === "1"){

                    localStorage.setItem("usuario", usuario);
                    localStorage.setItem("tipoUsuario", "us");
                    localStorage.setItem("nombreUsuario", resLogin.data.nombreUsuario);
                    
                    Auth.authenticate(() => {
                        
                        history.push('/catalogo');
                    })
                } 
                else {
                    setLoader(false);
                    setError("Usuario o contraseña incorrectos");
                }
            });
        } else {
            
            setLoader(true);

            let url = Constants.URL_BASE + Constants.WSVALIDARCLIENTE;

            let data = {
                "Nit": usuario.trim(),
                "DeviceType": "Web"
            };

            axios.post(url, data)
            .then(res => {

                setLoader(false);

                setValidacion(res.data);

                // if (res.data.Correos.length > 0) {
                //     setCorreoCodigo(res.data.Correos[0].substring(0,4) + "*".repeat(res.data.Correos[0].indexOf("@",0)-4) + res.data.Correos[0].substring(res.data.Correos[0].indexOf("@",0),res.data.Correos[0].length))
                // }

                // if (res.data.Telefonos.length > 0) {
                //     setCelularAyuda("*"+res.data.Telefonos[0].substring(res.data.Telefonos[0].length - 4, res.data.Telefonos[0].length))
                // }

                if(res.data.Activo == 0){
                    document.querySelector(".modal-registro").classList.toggle("show-modal");
                } else {
                    setCorreoCodigo(res.data.Correos[0])
                    document.querySelector(".modal-login").classList.toggle("show-modal");
                }
            });
        }

        event.preventDefault();
    }

    setTimeout(function(){
        if(document.querySelector(".login-content")){
            document.querySelector(".login-content").classList.add("show");
        }
    }, 3000);

    const handleTipoUsuario = (tipoUsuario) => {

        limpiarDatos();
        
        setTipoUsuario(tipoUsuario)
    }

    const limpiarDatos = () => {

        setUsuario("");
        setClave("");
        setError("");
    }

    const handleRecuperarClave = () => {
        document.querySelector(".modal-recuperacion").classList.toggle("show-modal");
    }
    
    // VISTAS
    return (
        <div className="login">
            <div className="login-content">
                <div className="splash" id="splash" style={{backgroundImage: `url(${Fondo})`}}>
                    <div className="logo-img">
                        {/* <img src={logo} alt="logo app"></img> */}
                    </div>
                    {/* <div className="maqueta-img">
                        <img src={maqueta} alt="logo app"></img>
                    </div> */}
                    <div className="footer">
                        <div className="footer-text">
                            {/* <p>Celuweb desde hace varios meses venia trabajando en la siguiente iniciativa, y quire poner a disposición de sus clientes como una contingencia la plataforma Emart</p> */}
                        </div>
                        <div className="footer-img">
                            {/* <img src={subtitulo} alt="logo app"></img> */}
                        </div>
                    </div>
                </div>
                <div className="login-form" id="login-form">
                    <div className="logo-img">
                        <img  src={logo_login} alt="logo app"></img>
                    </div>
                    { loader ? <Progress color={"#007daf"} /> : (
                        <form onSubmit={handleSubmit}>

                            <div className="container-form-login">
                                <div className="select-type">
                                    <div className={tipoUsuario == "us" ? "user-type-option option-1 active" : "user-type-option option-1"}  onClick={() => handleTipoUsuario("us")}>Colaborador Ramo</div>
                                    <div className={tipoUsuario == "cl" ? "user-type-option option-2 active" : "user-type-option option-2"} onClick={() => handleTipoUsuario("cl")}>Cliente</div>
                                </div>
                                <div className="form-control">
                                    <label> 
                                        { tipoUsuario == "us" ?
                                            <p>Usuario: </p> : 
                                            <p>Nit: </p>
                                        } 
                                    </label>
                                    <input 
                                        type="number"
                                        placeholder="Usuario"
                                        name="usuario"
                                        id="usuario"
                                        onChange={e => setUsuario(e.target.value)}
                                        value={usuario}>
                                    </input>
                                    
                                    { tipoUsuario == "us" ? (
                                        
                                        <React.Fragment>
                                            <label>Clave:</label>
                                            <input 
                                                type="password"
                                                placeholder="Clave"
                                                name="clave"
                                                id="clave"
                                                onChange={e => setClave(e.target.value)}
                                                value={clave}>
                                            </input>
                                            { error.length > 0 ? (
                                                <span className="error">
                                                    <p>{error}</p>
                                                </span>
                                            ) : null }
                                            <br/>
                                            <a style={{cursor:"pointer", color:"blue", textDecoration:"underline"}} onClick={() => handleRecuperarClave()}>Recuperar Contraseña</a>
                                        </React.Fragment>
                                    ) : null }

                                </div>
                            </div>
                            <button type="submit" className="btn-large">Ingresar</button>
                        </form>
                    )}
                    {/* { validacion.Telefonos ?  */}
                    <RegistroModal empresa={empresa} nit={usuario} validacion={validacion}/>
                     {/* :( null ) } */}

                    <CodigoActivacionModal validacion={validacion} empresa={empresa} />

                    {/* { validacion ?  */}
                    <LoginModal nit={usuario} empresa={empresa} correoCodigo={correoCodigo} tipoUsuario={tipoUsuario} />
                     {/* :( null ) } */}

                    <RecuperacionClaveModal />

                    <div className="logo-footer">
                        {/* <img  src={logo_login} alt="logo app"></img>
                        <img  src={logo_login} alt="logo app"></img> */}
                    </div>

                </div>
            </div>
        </div>
        // <div className="login">
        //     <div className="login-content">
        //         <div className="logo-img">
        //             <img src={logo} alt="logo app"></img>
        //         </div>
        //         <div>
        //             { loader ? <Progress color={"#ffffff"} /> : (
        //                 <form onSubmit={handleSubmit}>
        //                     <div className="form-control">
        //                         <label>NIT:</label>
        //                         <input 
        //                             type="number"
        //                             placeholder="NIT sin digito de verificación"
        //                             name="nit"
        //                             id="nit"
        //                             onChange={e => setNit(e.target.value)}
        //                             value={nit}>
        //                         </input>
        //                     </div>
        //                     <button type="submit" className="btn-large">Ingresar</button>
        //                 </form>
        //             )}
        //             <div className="footer">
        //                 <p><b>Powered by Celuweb</b></p>
        //                 <p>Version 1.0.0</p>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}


export default withRouter(Login);