import React, { useState, useEffect } from 'react';

import Navbar from '../navbar/Navbar';
import Tabbar from '../navbar/Tabbar';
import axios from 'axios';

import descuento from '../../assets/img/descuento.png';

import * as Constants from '../../constants/global';
import DetalleDescuentoModal from '../modal/DetalleDescuentoModal';
import noimage from '../../assets/img/noimage.png';


const Descuentos = () => {

    let codCliente = localStorage.getItem('codCliente');
    let sucursal = localStorage.getItem('sucursal');

    const [totalNavbar, setTotalNavbar] = useState();
    const [cantidad, setCantidad] = useState();
    const [listaDescuentos, setListaDescuentos] = useState([]);
    const [grupoDescuentos, setgrupoDescuentos] = useState([]);
    
    const [detalle, setDetalle] = useState([]);

    const loadDescuentos = async () => {  

        setListaDescuentos(JSON.parse(localStorage.getItem('listasDescuento')));
        setgrupoDescuentos(JSON.parse(localStorage.getItem('gruposDescuento')));
    }
    
    const getCantidad = () => {

        Constants.calcularDescuento()
        let carrito = JSON.parse(localStorage.getItem(codCliente)) || {};

        let iva = 0;
        let total = 0;
        let descuentoTotal = 0;
        let cantidad = 0;

        Object.keys(carrito).map((item, i) => {

            cantidad += carrito[item]["cantidad"];

            let subtotal = (carrito[item]["cantidad"] * carrito[item]["precio"]);
            let descuento = subtotal * (carrito[item]["porcentajeDescuento"] ? carrito[item]["porcentajeDescuento"] / 100 : 0 )
            iva += ( (subtotal - descuento) * (carrito[item]["iva"] ? carrito[item]["iva"] / 100 : 0) );

            descuentoTotal += descuento
        })

        setCantidad(cantidad)

        Object.keys(carrito).map((item, i) => {
            total += carrito[item]["cantidad"] * carrito[item]["precio"];
        })

        setTotalNavbar(total - descuentoTotal + iva);
    }

    const handleDetallePedido = (codDescuento) => {

        let infoDetalle

        grupoDescuentos.filter(o => o.codDescuento === codDescuento.toString()).map((item, i) => {

            let url = Constants.URL_BASE +
                Constants.WSPRODUCTO;
                

            if(codCliente){
                url += "?codCliente=" + codCliente;
            }

            if(sucursal){
                url += "&sucursal=" + sucursal;
            }

            if(item.codProducto){
                url += "&search=" + item.codProducto;
            }
            
            axios.get(url)
            .then(res => {

                let array = res.data;
            
                if(infoDetalle){
                    if(infoDetalle.result){
                        let combine = infoDetalle.result.concat(array.result);
                        array.result = combine;
                    }
                }
                
                infoDetalle = array;
                setDetalle(infoDetalle);

            })
        })

        // setDetalle(infoDetalle);

        document.querySelector(".modal-descuento").classList.toggle("show-modal");
    }

    useEffect(() => {
        loadDescuentos();
        getCantidad();
    }, [])

    return (
        <React.Fragment>
            <div className="navbar principal">
                <Navbar item={3} cantidad={cantidad} totalNavbar={totalNavbar} />
            </div>

            <div className="pedido-list descuentos">
                {listaDescuentos ? (
                    <div className="product-list">
                        {listaDescuentos.map((item, i) => (
                            
                            <div className="item item-carrito" key={i} style={{cursor:"pointer"}} onClick={() => handleDetallePedido(item.codDescuento)}>
                                <div className="start">
                                    <p className="nombre">Descripción: {item.descripcion}</p>
                                    
                                    <img src={descuento} className="img-descuento"></img>
                                </div>
                                <div className="end">
                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>

            <Tabbar item={3}/>

            <DetalleDescuentoModal detalle={detalle.result} noimage={noimage} /> 
            
        </React.Fragment>
    )
}


export default Descuentos;