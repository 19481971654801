import React, { useState, useEffect } from 'react';

import Filtros from '../navbar/Filtros';
import Agenda from './Agenda';

import NavbarInicial from '../navbar/NavbarInicial';
import TabbarInicial from '../navbar/TabbarInicial';

import {ReactComponent as Search} from '../../assets/icon/search.svg';

import { withRouter } from 'react-router-dom';

const Agendamiento = (props) => {

    const [search, setSearch] = useState();
    const [fecha, setFecha] = useState();
    const [tipoSucursal, setTipoSucursal] = useState();
    const [agencia, setAgencia] = useState();
    const [zona, setZona] = useState();

    const handleFecha = item => {
        setFecha(item);
    }

    const handleTipoSucursal = item => {
        setTipoSucursal(item);
    }

    const handleAgencia = item => {
        setAgencia(item);
    }

    const handleZona = item => {
        setZona(item);
    }

    const handleFiltros = (tipoSucursal, agencia, zona ) => {
        setTipoSucursal(tipoSucursal)
        setAgencia(agencia);
        setZona(zona);
    }

    const handleKeyPress = (e) => {
        if(e.key === "Enter"){
            handleBuscarCliente();
        }
    }

    const handleBuscarCliente = () => {
        let busqueda = document.getElementById("search").value;
        setSearch(busqueda);
    }

    useEffect(() => {
        
    }) 

    return (
        <React.Fragment>

            <div className="navbar">
                <div className="navbar-header">

                    <NavbarInicial item={3}/>

                    <Filtros handleAgencia={handleAgencia} handleZona={handleZona} handleTipoSucursal={handleTipoSucursal} handleFiltros={handleFiltros} handleFecha={handleFecha} ocultar={true} />

                    <div className="input-search">
                        <input 
                            type="text" 
                            placeholder="Buscar cliente..."
                            onKeyPress={handleKeyPress}
                            // onChange={(e) => setSearch(e.target.value)}
                            // value={search}>
                            >
                        </input>
                        <div className="button-Search">
                            <button onClick={() => handleBuscarCliente()}><Search className="svg-una-capa" style={{width:"25px", height:"25px"}}/></button>
                        </div>
                    </div>
                </div>
            </div>

            <Agenda search={search} agencia={agencia} zona={zona} tiposucursal={tipoSucursal} fecha={fecha} />

            <TabbarInicial item={3}/>
            
        </React.Fragment>
    )
}

export default Agendamiento;