import React, { Fragment, useRef, useState, useEffect } from 'react';

import moment from 'moment';
import axios from 'axios';
import { withRouter } from "react-router-dom";

import Navbar from '../navbar/Navbar';
import Tabbar from '../navbar/Tabbar';

import * as Constants from '../../constants/global';

import noimage from '../../assets/img/noimage.png';

import {ReactComponent as Minus} from '../../assets/icon/minus.svg';
import {ReactComponent as Plus} from '../../assets/icon/plus.svg';
import Trash from '../../assets/img/trash.png';
import error from '../../assets/icon/error.svg';

import SignatureCanvas from "react-signature-canvas";

import EncuestaModal from '../modal/EncuestaModal';
import ObservacionesModal from '../modal/ObservacionesModal';
// import DistribucionVentaModal from '../modal/DistribucionVentaModal';

const Carrito = (props) => {

    
    const [carrito, setCarrito] = useState();

    // const [carritoSucursal, setCarritoSucursal] = useState({});
    // const [totalCarritoSucursal, setTotalCarritoSucursal] = useState([]);

    const [total, setTotal] = useState(0);
    const [iva, setIva] = useState(0);
    const [maximoPedido, setMaximoPedido] = useState(false);
    // const [loader, setLoader] = React.useState(false);
    const [descuento_total, setDescuento_total] = useState(0);

    const [totalNavbar, setTotalNavbar] = useState();
    const [cantidad, setCantidad] = useState();

    const [kilos2019, setKilos2019] = useState(0);
    const [kilos2020, setKilos2020] = useState(0);
    const [porcCrecimiento, setPorcCrecimiento] = useState(0);
    const [rentabilidad, setRentabilidad] = useState(0);
    const [porcRentabilidad, setPorcRentabilidad] = useState(0);

    const [encuesta, setEncuesta] = React.useState({encuesta: {descripcion: ""}});

    // const [itemDistribucion, setItemDistribucion] = useState();
    // const [codItemDistribucion, setCodItemDistribucion] = useState();

    //Firma
    const signatureRef = useRef({});
    const [imageData, setImageData] = useState("");
    const [errorImage, setErrorImage] = useState(true);
    //Fin Firma

    const [correoEnvio, setCorreoEnvio] = React.useState(''); 

    let usuario = localStorage.getItem('usuario');

    let codCliente = localStorage.getItem('codCliente');
    let sucursal = localStorage.getItem('sucursal');
    let essucursal = localStorage.getItem('essucursal');
    // let correo = localStorage.getItem('correo');

    let encuestaEnviada = localStorage.getItem("encuestaEnviada");

    const saveSignature = (signature) => {
        setImageData(signature);
        setErrorImage(false);
    }

    const getCantidad = () => {

        let carrito = JSON.parse(localStorage.getItem(codCliente)) || {};

        let iva = 0;
        let total = 0;
        let descuentoTotal = 0;
        let cantidad = 0;

        Object.keys(carrito).map((item, i) => {

            cantidad += carrito[item]["cantidad"];

            let subtotal = (carrito[item]["cantidad"] * carrito[item]["precio"]);
            let descuento = (carrito[item]["descuentoAplicado"] ? carrito[item]["descuentoAplicado"] : 0);
            iva += ( (subtotal - descuento) * (carrito[item]["iva"] ? carrito[item]["iva"] / 100 : 0) );

            descuentoTotal += descuento
        })

        setCantidad(cantidad)

        Object.keys(carrito).map((item, i) => {
            total += carrito[item]["cantidad"] * carrito[item]["precio"];
        })

        setTotalNavbar(total - descuentoTotal + iva);

        return carrito
    }

    // const loadHistorial = async () => {
    //     let url = Constants.URL_BASE +
    //                 Constants.WSHISTORIAL +
    //                 "?codigo=" + sucursal + 
    //                 "&cliente=" + sucursal;

    //     axios.get(url)
    //     .then(res => {
    //         if(res.data.length >= sucursal.max_pedidos){
    //             setMaximoPedido(true);
    //         }else{
    //             setMaximoPedido(false);
    //         }
    //     });
    // }

    const getIva = () => {

        let descuento = Constants.calcularDescuento()

        setDescuento_total(descuento ? descuento : 0)

        let carrito = JSON.parse(localStorage.getItem(codCliente)) || {};

        let iva = 0;
        Object.keys(carrito).map((item, i) => {
            let subtotal = (carrito[item]["cantidad"] * carrito[item]["precio"]);
            let descuento = (carrito[item]["descuentoAplicado"] ? carrito[item]["descuentoAplicado"] : 0)
            iva += ( (subtotal - descuento) * (carrito[item]["iva"] ? carrito[item]["iva"] / 100 : 0) );
        })
        setIva(iva);
    }

    const getTotal = () => {


        let carrito = JSON.parse(localStorage.getItem(codCliente)) || {};

        let total = 0;
        Object.keys(carrito).map((item, i) => {
            total += carrito[item]["cantidad"] * carrito[item]["precio"];
        })

        setTotal(total);

        /***--------- Fin Calculo Subtotal ----------****/

    }

    // AUMENTA LA CANTTIDAD
    const handleMas = (codigo, item) => {    
        if(carrito[codigo]){
            carrito[codigo]["cantidad"] += 1;
        }else{
            carrito[codigo] = {
                nombre: item.nombre,
                precio: item.precio,
                cantidad: 1
            }
        }
        localStorage.setItem(codCliente, JSON.stringify(carrito));
        setCarrito(carrito);
        getIva();
        getTotal();

        let carro = getCantidad();

        setCarrito(carro);
        calcularDatos(carro);
    }

    const handleEliminar = (codigo, item) => {  

        if(carrito[codigo]){
            delete carrito[codigo];
        }  

        localStorage.setItem(codCliente, JSON.stringify(carrito));
        setCarrito(carrito);
        getIva();
        getTotal();

        let carro = getCantidad();

        setCarrito(carro);
        calcularDatos(carro);
    }

    const handleMenos = (codigo, item)  => {
        if(carrito[codigo]){
            if(carrito[codigo]["cantidad"] == 1){
                delete carrito[codigo];
            }else{
                carrito[codigo]["cantidad"] -= 1;
            }
        }
        localStorage.setItem(codCliente, JSON.stringify(carrito));
        setCarrito(carrito);
        getIva();
        getTotal();

        let carro = getCantidad();

        setCarrito(carro);
        calcularDatos(carro);
    }

    const handleCancelarPedido = () => {
        Object.keys(carrito).map((item, i) => {
            delete carrito[item];
        });

        localStorage.setItem(codCliente, JSON.stringify(carrito));
            props.history.replace("/pedido", "urlhistory");
    }

    const enviarPedido = (observacion) => {

        // let fechaActual = new Date(); 

        // let hora = fechaActual.getHours(); 
        // let minutos = fechaActual.getMinutes(); 

        if (essucursal == "S") {

            props.history.replace("/distribucionInventario", "urlhistory");
            
        } else {

            if(! errorImage){

                var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

                if(correoEnvio.match(mailformat)) {

                    // let validador = true;

                    // empresa.hora_limite = (empresa.hora_limite ? empresa.hora_limite : "")

                    // if(empresa.hora_limite == "") {
                    //     validador = true
                    // } else if (parseInt(hora+''+minutos) <= parseInt(empresa.hora_limite.replace(":","")) ){
                    //     validador = true
                    // } else {
                    //     alert("Su pedido no puede ser registrado en este momento.\nRecuerde que la hora máxima para enviar sus pedidos es: " + empresa.hora_limite +"")
                    // }

                    // if (validador){

                    let data = [];
                    let count = 0;
                    
                    let numeroDoc = moment().format('YYYYMMDDHHmmssSSS');

                    Object.keys(carrito).map((item, i) => {
                        count++;
                        data.push({
                            "NumeroDocPadre": numeroDoc,
                            "NumeroDoc": numeroDoc,
                            "CodigoCliente": codCliente,
                            "Sucursal": sucursal,
                            "FechaMovil": moment().format('YYYY-MM-DD HH:mm'),
                            "Precio": parseInt(carrito[item]["precio"]),
                            "Iva": parseInt(carrito[item]["iva"]),
                            "Posicion": count,
                            "Cantidad": parseInt(carrito[item]["cantidad"]),
                            "CodigoProducto": item,
                            "ValorDescuento": carrito[item]["descuentoAplicado"],
                            "Param1": carrito[item]["porcentajeDescuento"],
                            "Param2": parseInt(carrito[item]["cantidadDescuento"]),
                            "Param3": parseInt(carrito[item]["codigoDescuento"]),
                            "Param4": carrito[item]["clase"],
                            // "Param5": correoEnvio,
                            "DescripcionParam1": carrito[item]["porcentajeDescuento"] ? "Porcentaje Descuento" : "",
                            "DescripcionParam2": carrito[item]["cantidadDescuento"] ? "Cantidad Descuento Aplicada" : "",
                            "DescripcionParam3": carrito[item]["codigoDescuento"] ? "Codigo Descuento" : "",
                            "DescripcionParam4": carrito[item]["clase"] ? "Clase" : "",
                            "DescripcionParam5": correoEnvio
                            // ,"Observacion" : observacion
                        })
                    });

                    if(data.length > 0){
                        let url = Constants.URL_BASE + Constants.WSPEDIDO + "?usuario=" + usuario;

                        axios.post(url, {"ListaDetalle": data})
                        .then(res => {

                            Object.keys(carrito).map((item, i) => {
                                delete carrito[item];
                            });

                            localStorage.setItem(codCliente, JSON.stringify(carrito));

                            let data = {
                                "NumeroDocPadre": numeroDoc,
                                "Firma": imageData
                            }
    
                            let url = Constants.URL_BASE + Constants.WSENVIARCORREOPEDIDO

                            axios.post(url, data)
                            .then(res => {

                                alert(res.data.Mensaje ? res.data.Mensaje : "Pedido Almacenado Exitosamente");
                                
                                props.history.replace("/agendamiento", "urlhistory");
                            });

                            // if( (empresa.sucursal.primerPedido == 0 && encuestaEnviada == "0") ){
                            //     aplicarEncuesta();
                            // } else {
                            // }

                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    }
                } else {
                    alert("Correo ingresado no válido");
                }
            } else {
                alert("Debe ingresar firma");
            }
            // }
        }
    }

    // const handleObservaciones = () => {
    //     document.querySelector(".modal-observaciones").classList.toggle("show-modal");
    // }

    const handlePedido = () => {
        // handleObservaciones();
        enviarPedido("");
    }

    // const aplicarEncuesta = () => {
    //     let url = Constants.URL_BASE + Constants.WSENCUESTA;

    //     axios.get(url)
    //     .then(res => {
    //         setEncuesta(res.data[0]);
    //         document.querySelector(".modal-encuesta").classList.toggle("show-modal");
    //     });
    // }

    // {/* (Pedido) (Cantidad * precioPvp) - (Cantidad * precioConIva) */}
    // {/* (Pedido) ((Cantidad * precioPvp) - (Cantidad * precioConIva)) / (Cantidad * precioConIva) */}

    // <td>Rentabilidad</td>
    // <td>% Rentabilidad</td>

    const handleChangeCantidad = (codigo, item, valor) => {
        if(valor <= 0){
            delete carrito[codigo];
        }else{
            if(carrito[codigo]){
                carrito[codigo]["cantidad"] = parseInt(valor);
            }else{
                carrito[codigo] = {
                    nombre: item.nombre,
                    precio: item.precio,
                    cantidad: parseInt(valor)
                }
            }
        }
        localStorage.setItem(codCliente, JSON.stringify(carrito));
        setCarrito(carrito);
        getIva();
        getTotal();

        let carro = getCantidad();

        setCarrito(carro);
        calcularDatos(carro);
    }

    const getKilos2019 = (carrito) =>{
        let sumKilos2019 = 0;

        if(carrito != undefined){
            Object.keys(carrito).map((item, i) => {

                sumKilos2019 += carrito[item]["cantant"] * carrito[item]["pesobruto"]
            });
        }

        setKilos2019(sumKilos2019);
    }

    const getKilos2020 = (carrito) =>{
        let sumKilos2020 = 0;

        if(carrito != undefined){
            Object.keys(carrito).map((item, i) => {

                sumKilos2020 += carrito[item]["cantidad"] * carrito[item]["pesobruto"]
            });
        }

        setKilos2020(sumKilos2020);
    }

    const getPorcCrecimiento = (carrito) =>{

        let sumKilos2019 = 0;
        let sumKilos2020 = 0;

        if(carrito != undefined){
            Object.keys(carrito).map((item, i) => {
                sumKilos2019 += carrito[item]["cantant"] * carrito[item]["pesobruto"];
                sumKilos2020 += carrito[item]["cantidad"] * carrito[item]["pesobruto"];
            });
        }

        setPorcCrecimiento ( ((sumKilos2020 - sumKilos2019) / sumKilos2019) * 100 )
    }

    const getRentabilidad = (carrito) =>{

        let sumRentabilidad = 0;

        if(carrito != undefined){
            Object.keys(carrito).map((item, i) => {
                sumRentabilidad += (carrito[item]["cantidad"] * carrito[item]["preciopvp"]) - (carrito[item]["cantidad"] *  Math.round((carrito[item]["precio"] * (1 + ((carrito[item]["iva"] ? carrito[item]["iva"] : 0) / 100))), 0) )
            });
        }

        setRentabilidad(sumRentabilidad)
    }

    const getPorcRentabilidad = (carrito) =>{

        let sumRentabilidad = 0;
        let sumVenta = 0;

        if(carrito != undefined){
            Object.keys(carrito).map((item, i) => {
                sumRentabilidad += (carrito[item]["cantidad"] * carrito[item]["preciopvp"]) - (carrito[item]["cantidad"] *  Math.round((carrito[item]["precio"] * (1 + ((carrito[item]["iva"] ? carrito[item]["iva"] : 0) / 100))), 0) )
                sumVenta += (carrito[item]["cantidad"] * Math.round((carrito[item]["precio"] * (1 + ((carrito[item]["iva"] ? carrito[item]["iva"] : 0) / 100)))))
            });
        }

        setPorcRentabilidad(((sumRentabilidad + descuento_total) / sumVenta) * 100)
    }

    const calcularDatos = (carro) => {
        getKilos2019(carro);
        getKilos2020(carro);
        getPorcCrecimiento(carro);
        getRentabilidad(carro);
        getPorcRentabilidad(carro);
    }

    useEffect(() => {

        setCarrito(JSON.parse(localStorage.getItem(codCliente)) || {});
        getIva();
        getTotal();
        
        let carro = getCantidad();
        
        setCarrito(carro);
        calcularDatos(carro);

        // console.log("descuentos", Constants.calcularDescuento())

    }, [])

    return (
        <React.Fragment>
            <div className="navbar principal">
                <Navbar item={4} cantidad={cantidad} totalNavbar={totalNavbar}/>
            </div>

{/* {
    listasDescuento ? (
        <div>
            {
                listasDescuento.map((item_general, x)=>(
                        <p>{item_general.codDescuento}</p>
                    )
                )
            }
        </div>
    ) : null
} */}
    
            <div className="pedido-list carrito">
                {carrito ? (
                    <div className="product-list">
                        {Object.keys(carrito).map((item, i) => (
                            <div className="item item-carrito" key={i}>
                                <img width="120"
                                        className="img-product"
                                        // https://emartwebapi.celuwebdev.com/SyncEmart/
                                        src={ Constants.URL_IMG_PRODUCTOS + item.toString().trim() + '.jpg'}
                                        onError={(e)=>{e.target.onerror = null; e.target.src=noimage}}/>
                                <div className="start">
                                    
                                    <p className="codigo"><b>Codigo: </b>{item}</p>
                                    <p className="nombre"><b>{carrito[item].nombre}</b></p>
                                    <p className="precio">
                                        {Constants.copFormat(carrito[item].precio)}
                                        {/* {new Intl.NumberFormat("es-CO", {
                                            style: "currency",
                                            currency: "COP"
                                            }).format(carrito[item].precio)} */}
                                        </p>
                                    <p className="regular">
                                        Precio de Referencia 
                                        {" " + Constants.copFormat(carrito[item].precio) + " x " + carrito[item].cantidad} 
                                         {/* {" " + new Intl.NumberFormat("es-CO", {
                                         style: "currency",
                                         currency: "COP"
                                         }).format(carrito[item].precio) + " x " + carrito[item].cantidad}  */}
                                    </p>
                                </div>
                                <div className="end">
                                    <div className="action">
                                        <div className="controls">
                                            <button role="button" onClick={() => handleMenos(item, carrito[item])}>
                                                {/* <img src={minus} alt="icon minus" width="15px" height="15px"></img> */}
                                                <Minus className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                                            </button>
                                            <input type="number" 
                                                    onChange={e => handleChangeCantidad(item, carrito[item], e.target.value)}
                                                    value={carrito[item].cantidad} 
                                                    style={{backgroundColor:"white"}}
                                                    />
                                            <button role="button" onClick={() => handleMas(item, carrito[item])}>
                                                {/* <img src={plus} alt="icon plus" width="15px" height="15px"></img> */}
                                                <Plus className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                                            </button>
                                        </div>
                                        <a style={{cursor:"pointer"}} onClick={() => handleEliminar(item, carrito[item])}>
                                            <img src={Trash} width="20px" />
                                            {/* <Trash className="svg-dos-capas" style={{width:"20px"}}/> */}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>

            <div className="total">

                 <div>
                     <table className="table-productos">
                         <thead>
                            <tr>
                                <th className="color-azul">Kilos 2019</th>
                                <th className="color-azul">Kilos 2020</th>
                                <th className="color-azul">% Crecimiento</th>
                                <th className="color-azul">Rentabilidad</th>
                                <th className="color-azul">% Rentabilidad</th>
                            </tr>
                         </thead>
                         <tbody>
                            <tr>

                                {/* (Venta 2019) Cantidad * PesoBruto */}
                                {/* (Venta 2020) Cantidad * PesoBruto */}

                                {/* (Venta 2020) Cantidad * PesoBruto - (Venta 2019) Cantidad * PesoBruto / (Venta 2019) Cantidad * PesoBruto */}

                                {/* (Pedido) (Cantidad * precioPvp) - (Cantidad * precioConIva) */}
                                {/* (Pedido) ((Cantidad * precioPvp) - (Cantidad * precioConIva)) / (Cantidad * precioConIva) */}


                                <td>{Constants.copFormat(kilos2019, 2, " ")}</td>
                                <td>{Constants.copFormat(kilos2020, 2, " ")}</td>
                                <td>{Constants.copFormat(porcCrecimiento, 2, "% ")}</td>
                                <td>{Constants.copFormat(rentabilidad, 0)}</td>
                                <td>{Constants.copFormat(porcRentabilidad, 2, "% ")}</td>
                            </tr>
                         </tbody>
                     </table>
                 </div>

                <div className="total-content">

                    <div className="title">
                        <p>PRELIQUIDACIÓN</p>
                    </div>
                    <hr/>
                    <div className="item">
                        <p className="opc">Subtotal:</p>
                        <p className="val">
                            {Constants.copFormat(total)}
                            {/* {new Intl.NumberFormat("es-CO", {
                                style: "currency",
                                currency: "COP"
                            }).format(total)} */}
                        </p>
                    </div>

                    <div className="item">
                        <p className="opc">Descuento:</p>
                        <p className="val">
                            {Constants.copFormat(descuento_total)}
                        </p>
                    </div>

                    <div className="item">
                        <p className="opc">Iva:</p>
                        <p className="val">
                            {Constants.copFormat(iva)}
                            {/* {new Intl.NumberFormat("es-CO", {
                                style: "currency",
                                currency: "COP"
                            }).format(iva)} */}
                        </p>
                    </div>
                    <div className="item">
                        <p className="opc">Total:</p>
                        <p className="val">
                            {Constants.copFormat((total - descuento_total + iva))}
                            {/* {new Intl.NumberFormat("es-CO", {
                                style: "currency",
                                currency: "COP"
                            }).format((total - descuento_total + iva))} */}
                        </p>
                    </div>

                    { essucursal !== "S" ? (
                        <Fragment>

                            <hr/>

                            <pre>
                                { errorImage ? <div style={{color:"#e11223"}}>La firma es obligatoria</div> : false }
                            </pre>

                            <SignatureCanvas
                                canvasProps={{
                                    height: 200,
                                    style:{"border":"1px solid #000000", "width":"100%"}
                                }}
                                minWidth={2}
                                maxWidth={3}
                                penColor="green"
                                ref={signatureRef}
                                onEnd={()=>(
                                    saveSignature(signatureRef.current.getTrimmedCanvas().toDataURL("image/jpg"))
                                )}
                            />
                            
                            <button className="btn-large"
                                style={{width:"30%", margin:"auto"}}
                                onClick={() => {
                                signatureRef.current.clear();
                                saveSignature(null);
                                setErrorImage(true);
                            }}> Limpiar </button>

                            <br />

                            <input 
                                className="input"
                                type="email"
                                placeholder="E-mail"
                                onChange={e => setCorreoEnvio(e.target.value)}
                                value={correoEnvio}>
                            </input>

                        </Fragment>
                    ) : null}

                </div>
                
                <button type="submit" className="btn-large outline" onClick={() => handleCancelarPedido()}>Cancelar pedido</button>
                
                {maximoPedido ? (
                    <div className="carrito-validacion">
                        <div>
                            <img src={error} alt="icon plus" width="20px" height="20px"></img>
                        </div>
                        <div className="contenido">
                            <p><b>Cantidad maxima de pedidos superada</b></p>
                            {/* <p>Solo se permiten maximo ({empresa.max_pedidos}) pedidos por día.</p> */}
                        </div>
                    </div>
                ) : (
                    // <React.Fragment>
                    //     {empresa.pedido_minimo > (total + iva) ? (
                    //         <div className="carrito-validacion">
                    //             <div>
                    //                 <img src={error} alt="icon plus" width="20px" height="20px"></img>
                    //             </div>
                    //             <div className="contenido">
                    //                 <p><b>El valor de el pedido es muy poco</b></p>
                    //                 <p>Los pedidos deben ser mayor a 
                    //                     {Constants.copFormat( empresa.pedido_minimo )}
                    //                     {/* {" " + new Intl.NumberFormat("es-CO", {
                    //                         style: "currency",
                    //                         currency: "COP"
                    //                     }).format(empresa.pedido_minimo )} */}
                    //                 </p>
                    //             </div>
                                
                    //         </div>
                    //     ) : (
                    //         <button type="submit" className="btn-large" onClick={() => handlePedido()}>Enviar pedido</button>
                    //     )}
                    // </React.Fragment>
                    <button type="submit" className="btn-large" onClick={() => handlePedido()}>
                        { essucursal == "S" ? "Distribuir Inventario" : "Enviar Pedido" }
                    </button>
                    
                )} 

                {/* {empresa.sucursal.texto_liquidacion ? (
                    <div className="carrito-informacion">
                        <div>
                            <img src={error} alt="icon plus" width="20px" height="20px"></img>
                        </div>
                        <div className="contenido">
                            <text dangerouslySetInnerHTML={{ __html: empresa.sucursal.texto_liquidacion_web.replace("&lt;","<").replace("&gt;",">") }}></text>
                        </div>
                    </div>
                ) : null } */}
            </div>

            <Tabbar item={4}/>
            {/* <DistribucionVentaModal codCliente={codCliente} 
                itemDistribucion={itemDistribucion} 
                codItemDistribucion={codItemDistribucion} /> */}
            {/* <EncuestaModal encuesta={encuesta} codCliente={empresa.sucursal.codigo} codProveedor={empresa.codigo_empresa} codEmpresa={empresa.codigo_empresa} enviarPedido={handlePedido}  />
            <ObservacionesModal codEmpresa={empresa.codigo_empresa} enviarPedido={enviarPedido}  /> */}
        

    </React.Fragment>

        
    )
}



export default withRouter(Carrito);