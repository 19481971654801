import React, { useState, useEffect } from 'react';
import axios from 'axios';

import * as Constants from '../../constants/global';

import Progress from "../util/Progress";

import 'rc-time-picker/assets/index.css';
import moment, { min } from 'moment';
import TimePicker from 'rc-time-picker';

const AgendamientoModal = (props) => {

    let usuario = localStorage.getItem("usuario");
    let tipoUsuario = localStorage.getItem("tipoUsuario");
    
    const [fecha, setFecha] = useState();
    const [horaInicial, setHoraInicial] = useState();
    const [horaFinal, setHoraFinal] = useState();

    const [tema, setTema] = useState("");
    const [observacion, setObservacion] = useState("");
    // const [email, setEmail] = useState("");

    const [horasExcluidas, setHorasExcluidas] = useState([]);

    const handleCancelar = () => {
        limpiarDatos();
        document.querySelector(".modal-agendamiento").classList.remove("show-modal");
    }

    const handleEnviar = () => {

        if(tema != "" && observacion != "" && fecha != null && horaInicial != null && horaFinal != null){

            if (horaFinal.diff(horaInicial, 'minutes') >= 30) {

                let urlValidar = Constants.URL_BASE + Constants.WSVALIDARDISPONIBILIDADAGENDAMIENTO;

                urlValidar += "?usuario=" + usuario;
                urlValidar += "&date=" + fecha;
                urlValidar += "&horaInicial=" + horaInicial.format("HH:mm");
                urlValidar += "&horaFinal=" + horaFinal.format("HH:mm");

                axios.get(urlValidar)
                .then(res => {
                    if (res.data == "OK"){

                        let url = Constants.URL_BASE + Constants.WSAGENDAMIENTO;

                        let info = [];

                        info.push({
                            "Usuario": usuario,
                            "CodCliente": props.codCliente,
                            "Sucursal": props.essucursal == "S" ? "" : props.sucursal,
                            "FechaVisita": fecha,
                            "HoraInicial": horaInicial.format("HH:mm"),
                            "HoraFinal": horaFinal.format("HH:mm"),
                            "Tema": tema,
                            "Observacion": observacion,
                            // "Email": email,
                            "EsSucursal": props.essucursal,
                            "Regional": props.regional
                        });
                
                        let data = {"ListaDetalleAgenda": info}
        
                        axios.post(url, data)
                        .then(res => {
                            if(res.data.Estado == "OK"){
                                alert(res.data.Mensaje);
                                props.loadClientes(0);
                                document.querySelector(".modal-agendamiento").classList.remove("show-modal");
                            } else {
                                alert("Ocurrio un error al crear el agendamiento");
                                document.querySelector(".modal-agendamiento").classList.remove("show-modal");
                            }
                        })
                    } else if(res.data == "ERROR") {
                        alert("Los horarios seleccionados ya se encuentran ocupados.");
                    } else {
                        alert("Ha ocurrido un error al validar la disponibilidad.");
                    }
                })
                .catch(error => {
                    alert("Ha ocurrido un error al validar la disponibilidad.");
                });
            } else {
                alert("El agendamiento debe tener mínimo 30 minutos");
            }
        } else {
            alert("Debe ingresar todos los datos");
        }
    }

    function excludeMinutes(length, excludedOptions) {
        const arr = [];
        for (let value = 0; value < length; value++) {
            if (excludedOptions.indexOf(value) < 0) {
                arr.push(value);
            }
        }
        return arr;
    }
    
    function excludeMinutesEnd(initial, end, length, excludedOptions) {
        const arr = [];

        let minFinal = moment(initial).add(30,'minutes').minute()

        let horaini = moment(initial).hour()
        let horafin = moment(initial).add(30,'minutes').hour()

        let horafinSeleccionada = moment(horaFinal).hour()

        if (horafin == horafinSeleccionada){
            for (let value = 0; value < length; value++) {
                
                if (excludedOptions.indexOf(value) < 0) {
                    arr.push(value);
                } else if (value < minFinal && horaini == horafin) {
                    arr.push(value);
                } else if(horaini < horafin && value < minFinal) {
                    arr.push(0);
                }
            }
        } else {
            for (let value = 0; value < length; value++) {
                if (excludedOptions.indexOf(value) < 0) {
                    arr.push(value);
                }
            }
        }
        return arr;
    }

    function excludeHoursEnd(initial) {
        const arr = [];

        let length = 24;

        let horafin = moment(initial).add(30,'minutes').hour()

        for (let value = 0; value < length; value++) {

            if (value < horafin) {
                arr.push(value);
            }
        }
        return arr;
    }

    const handleHoraInicial = (e) => {

        if (e != null) {
            setHoraInicial(moment(e._d));
            setHoraFinal(moment(e._d).add(30, 'minutes'));
        } else {
            setHoraInicial(null)
            setHoraFinal(null)
        }
    }

    const handleHoraFinal = (e) => {

        if(e != null){
            setHoraFinal(moment(e._d))
        } else {
            setHoraFinal(null)
        }

    }

    const handleFecha = (fecha) => {

        if (fecha != ""){
            setFecha(fecha);
        } else {
            setFecha(null);
        }
    }

    const limpiarDatos = () => {

        setHoraInicial(null)
        setHoraFinal(null)

        setTema("RUEDA DE NEGOCIOS 2020")
        setObservacion("")
        // setEmail("")
    }

    useEffect(() => {
        limpiarDatos();
    },[props.codCliente, props.sucursal, fecha])

    return (

        <div className="modal modal-agendamiento">
            <div className="modal-content">
                <div className="title">
                    <p>Observaciónes</p>
                </div>
                <div className="content sm agendamiento">
                    <table cellPadding={"10"}> 
                        <tbody >
                            <tr>
                                <td style={{width:104}}>
                                    <label>Sucursal: </label>
                                </td>
                                <td>
                                    <input 
                                        type="text"
                                        id="sucursal"
                                        disabled
                                        value={props.sucursal}/>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width:104}}>
                                    <label>Fecha: </label>
                                </td>
                                <td>
                                    <input 
                                        type="date" 
                                        id="fecha"
                                        onChange={e => handleFecha(e.target.value)}
                                        value={fecha}
                                        min={Constants.getDate(0, "-")} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Hora Inicial: </label>
                                </td>
                                <td>
                                    {/* <TimePicker value={horaInicial} showSecond={false} onChange={e => handleHoraInicial(e._d)} /> */}
                                    <TimePicker
                                        value={horaInicial}
                                        showSecond={false}
                                        disabled={fecha == null}
                                        onChange={e => handleHoraInicial(e)}
                                        disabledMinutes={() => excludeMinutes(60,[0,15,30,45])}
                                        hideDisabledOptions
                                        />
                                    {/* <input 
                                        type="time"
                                        id="horaInicial"
                                        onChange={e => setHoraInicial(e.target.value)}
                                        defaultValue={horaInicial} /> */}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Hora Final: </label>
                                </td>
                                <td>
                                    <TimePicker 
                                        value={horaFinal} 
                                        showSecond={false} 
                                        disabled={horaInicial == null}
                                        onChange={e => handleHoraFinal(e)}
                                        disabledMinutes={() => excludeMinutesEnd(horaInicial, horaFinal, 60,[0,15,30,45])}
                                        disabledHours={() => excludeHoursEnd(horaInicial)}
                                        hideDisabledOptions
                                    />
                                    {/* <input 
                                        type="time"
                                        id="horaFinal"
                                        onChange={e => setHoraFinal(e.target.value)}
                                        defaultValue={horaFinal} /> */}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Tema: </label>
                                </td>
                                <td>
                                    <input 
                                        type="text" 
                                        id="tema"
                                        onChange={e => setTema(e.target.value)}
                                        value={tema} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Observacion: </label>
                                </td>
                                <td>
                                    <input 
                                        type="text" 
                                        id="observacion"
                                        onChange={e => setObservacion(e.target.value)}
                                        value={observacion} />
                                </td>
                            </tr>
                            {/* <tr>
                                <td>
                                    <label>Email: </label>
                                </td>
                                <td>
                                    <input 
                                        type="email" 
                                        id="email"
                                        onChange={e => setEmail(e.target.value)}
                                        value={email} />
                                </td>
                            </tr> */}
                        </tbody>
                    </table>
                </div>
                <div className="footer">
                    <button type="submit" className="btn-large" onClick={() => handleEnviar()}>Agendar</button>
                    <button type="submit" className="btn-large outline" onClick={() => handleCancelar()}>Cancelar</button>
                </div>
            </div>
        </div>
    )
}

export default AgendamientoModal;