import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
// import logo from '../../assets/img/logo.png';
import pedido from '../../assets/icon/pedido.svg';
import sugerido from '../../assets/icon/sugerido.svg';
import catalogo from '../../assets/icon/catalogo.svg';
import excel from '../../assets/icon/excel.svg';
//import perfil from '../../assets/icon/perfil.svg';
import carrito from '../../assets/icon/carrito.svg';

const Tabbar = (props) => {

    const [tipoUsuario, setTipoUsuario] = useState("");

    const handleRedirect = item => {
        props.history.replace(item, "urlhistory");
    }

    useEffect(() => {
        setTipoUsuario(localStorage.getItem("tipoUsuario"));
    });

    return (
        <div className="tabmenu">
            <div className="tab-content">

                <div className={props.item == 1 ? "tab active" : "tab"}
                    onClick={ () => handleRedirect('/catalogo')}>
                    <img src={catalogo} alt="icon menu" width="20px" height="20px"></img>
                    <span>Catalogo</span>
                </div>

                {tipoUsuario === "us" ? (
                    <div className={props.item == 2 ? "tab active" : "tab"}
                        onClick={ () => handleRedirect('/listadoClientes')}>
                        <img src={catalogo} alt="icon menu" width="20px" height="20px"></img>
                        <span>Clientes</span>
                    </div>
                ) : null}

                {tipoUsuario === "us" ? (
                    <div className={props.item == 3 ? "tab active" : "tab"}
                        onClick={ () => handleRedirect('/agendamiento')}>
                        <img src={catalogo} alt="icon menu" width="20px" height="20px"></img>
                        <span>Agendamiento</span>
                    </div>
                ) : null}

                <div className={props.item == 4 ? "tab active" : "tab"}
                    onClick={ () => handleRedirect('/historico')}>
                    <img src={sugerido} alt="icon menu" width="20px" height="20px"></img>
                    <span>Historico</span>
                </div>

                {tipoUsuario === "us" ? (
                    <div className={props.item == 5 ? "tab active" : "tab"}
                        onClick={ () => handleRedirect('/myaccount')}>
                        <img src={catalogo} alt="icon menu" width="20px" height="20px"></img>
                        <span>Perfil</span>
                    </div>
                ) : null}
                {tipoUsuario === "cl" ? (
                    <div className={"tab"}
                        onClick={ () => handleRedirect('/pedido')}>
                        <img src={catalogo} alt="icon menu" width="20px" height="20px"></img>
                        <span>Tomar Pedido</span>
                    </div>
                ) : null}

                
                
                
            </div>
        </div>
    )
}


export default withRouter(Tabbar);