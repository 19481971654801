import axios from "axios";

//LOCALHOST
// export const URL_BASE = "http://localhost/ApiRuedaNegociosRamo/";
// export const URL_BASE = "http://localhost:36025/";

//PRUEBAS
export const URL_BASE = "https://mercadeo.celuwebdev.com/ApiRamoNegocios/";

//PRD
// export const URL_BASE = "https://mercadeo.celuwebdev.com/ApiRamoNegociosPRD/";

//RUTAS IMAGENES
export const URL_BANNERS =
  "https://mercadeo.celuwebdev.com/ApiRamoNegocios/Banners/";
export const URL_IMG_PRODUCTOS =
  "https://mercadeo.celuwebdev.com/ApiRamoNegocios/imgProductos/";


export const WSEMPRESA = "api/empresa/sucursal";
export const WSCATEGORIA = "api/categoria";
export const WSPRODUCTO = "api/producto";
export const WSSUGERIDO = "api/sugerido";
export const WSPEDIDO = "api/pedido";
export const WSHISTORIAL = "api/pedido/historial";
export const WSBANNERS = "api/Empresa/Banners";
export const WSLISTASDESCUENTO = "api/Cliente/ListasDescuentos";
export const WSGRUPOSDESCUENTO = "api/Cliente/GruposDescuentos";
export const WSLOGINCLIENTE = "api/Cliente/Login";
export const WSVALIDARCLIENTE = "api/Cliente/Validar";
export const WSREGISTROUSUARIO = "api/Cliente/Registro";
export const WSENVIARCODIGO = "api/Cliente/EnviarCodigo";
export const WSACTIVARCLIENTE = "api/Cliente/Activar";
export const WSRECUPERACIONCLAVE = "api/Cliente/RecuperacionClave";
export const WSCARTERACLIENTE = "api/Cartera";
export const WSENCUESTA = "api/Encuesta";
export const WSHISTORICO = "api/Empresa/Historial";
export const WSDETAIL = "api/Empresa/HistorialDetalle";
export const WSDETAILPRODUCT = "api/Empresa/HistorialDetalleProducto";
export const WSPRODUCTOSCOMBO = "api/Cliente/Combos";
export const WSUPDATECLIENTE = "api/Cliente/ModificarCliente";
export const WSUPDATEPASSWORD = "api/Cliente/CambiarClave";


//Nuevas Apis

//POST
export const WSFILTROAGENCIA = "api/Cliente/FiltroAgencia";
export const WSFILTROZONA = "api/Cliente/FiltroZona";
export const WSENVIARCORREOPEDIDO = "api/Pedido/EnviarCorreoPedido";
export const WSRECUPERACIONCLAVEUSUARIO = "api/Cliente/RecuperacionClaveUsuario";

//GET
export const WSGETCLIENTE = "api/Cliente";
export const WSGETSUCURSALESCLIENTE = "api/Cliente/SucursalesVenta";
export const WSHORASAGENDAMIENTO = "api/Agendamiento/HorasAgenda";
export const WSVALIDARDISPONIBILIDADAGENDAMIENTO = "api/Agendamiento/ValidarDisponibilidad";
export const WSPRODUCTOCATALOGO = "api/producto/Catalogo";
export const WSDATOSESTADISTICAS = "api/Empresa/DatosEstadisticas";

// GET Y POST
export const WSAGENDAMIENTO = "api/Agendamiento";

let cantDecimales = 0;

export const getDate = (
  complete = 1,
  dateSeparator = "-",
  timeSeparator = ":"
) => {
  let d = new Date(),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    minute = "" + d.getMinutes(),
    second = "" + d.getSeconds();

  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }
  if (minute.length < 2) {
    minute = "0" + minute;
  }
  if (second.length < 2) {
    second = "0" + second;
  }

  let fecha = [year, month, day].join(dateSeparator);
  let time = [minute, second].join(timeSeparator);

  let salida = complete == 1 ? fecha + " " + time : fecha;

  return salida;
};

export const copFormat = (
  nStr,
  decimals,
  signo,
  milesSeparator,
  decimalSeparator
) => {
  decimals = decimals || cantDecimales;
  milesSeparator = milesSeparator || ".";
  decimalSeparator = decimalSeparator || ",";
  signo = signo || "$";

  nStr = nStr ? parseFloat(nStr) : 0;

  var x = nStr.toString().split(".");
  var x1 = x[0];
  var x2 = nStr.toFixed(decimals).split(".")[1];
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1" + milesSeparator + "$2");
  }
  return signo + " " + x1 + (x2 ? decimalSeparator + x2 : "");
};

export const calcularDescuento = () => {
  let sucursal = localStorage.getItem("sucursal");
  let codCliente = localStorage.getItem("codCliente");

  let carrito = JSON.parse(localStorage.getItem(codCliente)) || {};

  let listCarrito = JSON.parse(localStorage.getItem(codCliente)) || {};
  let listasDescuento = JSON.parse(localStorage.getItem("listasDescuento"));
  let gruposDescuento = JSON.parse(localStorage.getItem("gruposDescuento"));

  let descuento_total = 0;

  if (listasDescuento && gruposDescuento) {
    listasDescuento.map((item_general, x) => {

      if (Object.keys(listCarrito).length > 0) {
        let aplicarDescuento = [];

        Object.keys(listCarrito).map((item, i) => {
          if (
            gruposDescuento.find(
              (element) =>
                element.codDescuento == item_general.codDescuento &&
                element.codProducto == item
            )
          ) {
            aplicarDescuento[item] = {
              codigo: item,
              cantidad: listCarrito[item].cantidad,
              precio: listCarrito[item].precio,
              codigoDescueto: item_general.codDescuento,
            };
          }
        });

        let totalValor = 0;
        let totalCantidad = 0;

        if (
          item_general.tipoDescuento === "B" &&
          item_general.aplicadoEn === "P"
        ) {

          aplicarDescuento.map((producto, y) => {
            totalValor += producto.cantidad * producto.precio;
          });

          if (
            totalValor >= item_general.valorMinimo &&
            totalValor <= item_general.valorMaximo
          ) {

            let descuento = (totalValor * item_general.porcentaje) / 100;
            descuento_total += descuento;

            aplicarDescuento.map((producto, y) => {
              carrito[producto.codigo].codigoDescuento =
                item_general.codDescuento;
              carrito[producto.codigo].cantidadDescuento = producto.cantidad;
              carrito[producto.codigo].porcentajeDescuento =
                item_general.porcentaje;
              carrito[producto.codigo].descuentoAplicado =
                producto.cantidad *
                producto.precio *
                (item_general.porcentaje / 100);
              carrito[producto.codigo].clase = item_general.clase;

              delete listCarrito[producto.codigo];
            });
          } else {
            aplicarDescuento.map((producto, y) => {
              delete carrito[producto.codigo].codigoDescuento;
              delete carrito[producto.codigo].cantidadDescuento;
              delete carrito[producto.codigo].porcentajeDescuento;
              delete carrito[producto.codigo].descuentoAplicado;
              delete carrito[producto.codigo].clase;
            });
          }
        } else if (
          item_general.tipoDescuento === "B" &&
          item_general.aplicadoEn === "C"
        ) {
          aplicarDescuento.map((producto, y) => {
            totalCantidad += producto.cantidad;
            totalValor += producto.cantidad * producto.precio;
          });

          if (totalCantidad >= item_general.cantidadMinima) {
            let descuento = (totalValor * item_general.porcentaje) / 100;
            descuento_total += descuento;

            aplicarDescuento.map((producto, y) => {
              carrito[producto.codigo].codigoDescuento =
                item_general.codDescuento;
              carrito[producto.codigo].cantidadDescuento = producto.cantidad;
              carrito[producto.codigo].porcentajeDescuento =
                item_general.porcentaje;
              carrito[producto.codigo].descuentoAplicado =
                producto.cantidad *
                producto.precio *
                (item_general.porcentaje / 100);
              carrito[producto.codigo].clase = item_general.clase;

              delete listCarrito[producto.codigo];
            });
          }
        } else if (item_general.tipoDescuento === "V") {
          aplicarDescuento.sort(compareValues("cantidad"));

          let productosVolumen = [];

          aplicarDescuento.map((producto, y) => {
            totalCantidad += producto.cantidad;
          });

          if (totalCantidad >= item_general.cantidadMinima) {
            totalCantidad = parseInt(
              totalCantidad - (totalCantidad % item_general.cantidadMinima)
            );

            aplicarDescuento.map((producto, y) => {
              let cantidadDescuento = 0;
              let cantidadSinDescuento = 0;

              if (producto.cantidad <= totalCantidad) {
                cantidadDescuento = producto.cantidad;
                cantidadSinDescuento = producto.cantidad - cantidadDescuento;
                totalCantidad -= cantidadDescuento;
              } else {
                cantidadSinDescuento = producto.cantidad - totalCantidad;
                cantidadDescuento = producto.cantidad - cantidadSinDescuento;
                totalCantidad = 0;
              }

              productosVolumen[producto.codigo] = {
                codigo: producto.codigo,
                cantidad_Descuento: cantidadDescuento,
                precio: producto.precio,
              };
            });

            let descuento = 0;

            productosVolumen.map((productoVlmn, y) => {
              let descuentoProducto =
                productoVlmn.cantidad_Descuento *
                productoVlmn.precio *
                (item_general.porcentaje / 100);

              descuento += descuentoProducto;

              carrito[productoVlmn.codigo].codigoDescuento =
                item_general.codDescuento;
              carrito[productoVlmn.codigo].cantidadDescuento =
                productoVlmn.cantidad_Descuento;
              carrito[productoVlmn.codigo].porcentajeDescuento =
                item_general.porcentaje;
              carrito[
                productoVlmn.codigo
              ].descuentoAplicado = descuentoProducto;
              carrito[productoVlmn.codigo].clase = item_general.clase;

              delete listCarrito[productoVlmn.codigo];
            });

            descuento_total += descuento;
          } else {
            aplicarDescuento.map((producto, y) => {
              delete carrito[producto.codigo].codigoDescuento;
              delete carrito[producto.codigo].cantidadDescuento;
              delete carrito[producto.codigo].porcentajeDescuento;
              delete carrito[producto.codigo].descuentoAplicado;
              delete carrito[producto.codigo].clase;
            });
          }
        }
      } else {
        // console.log("no se cargo info");
      }
    });

    localStorage.setItem(codCliente, JSON.stringify(carrito));
    return descuento_total;
  }
};

const compareValues = (key, order = "desc") => {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
};

export const cargarListasDescuento = async (
  sucursal,
  codCliente,
  tipocredito
) => {
  let url = URL_BASE + WSLISTASDESCUENTO + "?codCliente=" + codCliente;

  if (sucursal) {
    url += "&sucursal=" + sucursal;
  }

  if (tipocredito) {
    url += "&tipocredito=" + tipocredito;
  }

  // console.log("url lista", url)

  await axios.get(url).then((res) => {
    // console.log("listasDescuento", res.data);
    localStorage.setItem("listasDescuento", JSON.stringify(res.data));
  });
};

export const cargarGruposDescuento = async (
  sucursal,
  codCliente,
  tipocredito
) => {
  let url = URL_BASE + WSGRUPOSDESCUENTO + "?codCliente=" + codCliente;

  if (sucursal) {
    url += "&sucursal=" + sucursal;
  }

  if (tipocredito) {
    url += "&tipocredito=" + tipocredito;
  }

  // console.log("url grupo", url)

  await axios.get(url).then((res) => {
    // console.log("gruposDescuento", res.data);
    localStorage.setItem("gruposDescuento", JSON.stringify(res.data));
  });
};
