import React from 'react';
import { withRouter } from "react-router-dom";
import * as Constants from '../../constants/global';

import close, { ReactComponent } from '../../assets/icon/close_grey.svg';

const DetalleDescuentoModal = (props) => {

    let empresa = JSON.parse(localStorage.getItem('empresa'));

    const handleClose = () => {
        document.querySelector(".modal-descuento").classList.remove("show-modal");
    }

    return (
        <div className="modal modal-descuento">
            <div className="modal-content modal-lg">       
                <div className="title">
                    <p>Productos Descuento</p>
                    <span className="close-button" onClick={() => handleClose()}>
                        <img src={close} width="15" height="15" alt="icon close"/>
                    </span>  
                </div>
                <div className="content full-content" >
                    <div className="list-items">
                        { props.detalle ? props.detalle.map((item, i) => (
                            <div className="item detalle-combo" style={{cursor:"default"}} key={i} >
                                <div className="card-img">
                                    <img className="img-product"
                                        src={Constants.URL_IMG_PRODUCTOS + item.codigo.toString().trim() + '.jpg'}
                                        onError={(e)=>{e.target.onerror = null; e.target.src=props.noimage}} >
                                    </img> 
                                </div>
                                <p><b>{item.nombre}</b></p>
                            </div>
                        )) : null }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(DetalleDescuentoModal);